import React,{useEffect, useState} from 'react';
import { useSelector, useDispatch } from "react-redux";
import { Link,useParams,useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import Loader from "../Loader";
import Sidebar from "./Sidebar";
import AccountTreeIcon from "@material-ui/icons/AccountTree";
import MetaData from '../../components/MetaData';
import { clearErrors, logo } from "../../actions/userAction";
import { UPLOAD_LOGO_RESET } from "../../constants/userConstant";

const AddLogo = () => {

	const dispatch = useDispatch();
  const alert = useAlert();
  const navigate = useNavigate();

  const { error, loading, success } = useSelector((state) => state.newlogo);

  // const [logoInfo, setLogoInfo] = useState({
  //   name: "",
  //   status: "",
  // });

  // const { name, status } = logoInfo;
	const [name, setName] = useState("");
	const [status, setStatus] = useState("");
	const [images, setImages] = useState([]);
	const [imagesPreview, setImagesPreview] = useState([]);

	useEffect(() => {
        if (error) {
          alert.error(error);
          dispatch(clearErrors());
        }

        if (success) {
		      alert.success("Logo Uploaded Successfully");
		      navigate("/admin/logo-list");
		      dispatch({ type: UPLOAD_LOGO_RESET });
		    }


    }, [dispatch, error, alert, success]);

    const createLogoSubmitHandler = (e) => {
        e.preventDefault();

        const myForm = new FormData();

        myForm.set("name", name);
        myForm.set("status", status);
        images.forEach((image) => {
	      myForm.append("images", image);
	    });
	    dispatch(logo(myForm));
    };

    const createLogoImagesChange = (e) => {
      const files = Array.from(e.target.files);

	    setImages([]);
	    setImagesPreview([]);

	    files.forEach((file) => {
	      const reader = new FileReader();

	      reader.onload = () => {
	        if (reader.readyState === 2) {
	          setImagesPreview((old) => [...old, reader.result]);
	          setImages((old) => [...old, reader.result]);
	        }
	      };

	      reader.readAsDataURL(file);
	    });
    };

	return (
		<>
{loading ? (
        <Loader />
      ) : (
      	<>

		      		<div className="dashboard-panel">
					<div className="container">
						<MetaData title={`Upload Logo`} />
						<div className="row">
							<Sidebar />	
							<div className="col-lg-9 col-md-8">
								<div className="dashboardContainer">
							        <h3>Upload Logo</h3>
							        <div className="col-12">
		                    			<form
							            className="logoUpload"
							            onSubmit={createLogoSubmitHandler}
							          >

							          	{/*<div className="single-form" id="registerImage">
			                                <img src={imagePreview} alt="Avatar Preview" />
			                                <input
			                                    type="file"
			                                    name="image"
			                                    accept="image/*"
			                                    onChange={createLogoImagesChange}
			                                  />
			                            </div>*/}

			                            <div className="single-form">
			                                <input type="text" placeholder="Name" name="name" value={name} onChange={(e) => setName(e.target.value)}/>
			                            </div>

			                            <div className="single-form logo-status">
											              <AccountTreeIcon />
											              <select name="status" onChange={(e) => setStatus(e.target.value)}>
											                <option value="">Choose Status</option>
											                <option value="show">Show</option>
											                <option value="hide">Hide</option>
											              </select>
											            </div>

											            <div className="single-form" id="createProductFormFile">
							              <input
							                type="file"
							                accept="image/*"
							                onChange={createLogoImagesChange}
							                multiple={true}
							              />
							            </div>

							            <div className="single-form" id="createCateFormImage">
							              {imagesPreview.map((image, index) => (
							                <img key={index} src={image} alt="Category Preview" />
							              ))}
							            </div>

							            <button
							            className="mt-2 btn btn-primary btn-hover-dark paymentFormBtn"
							              id="createProductBtn"
							              type="submit"
							              disabled={loading ? true : false}
							            >
							              Upload
							            </button>
							          </form>
		  						    </div>
							    </div>
							</div>
						</div>
					</div>
					</div>
</>
		)}
			
		</>
	)
}

export default AddLogo