export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";

export const REGISTER_USER_REQUEST = "REGISTER_USER_REQUEST";
export const REGISTER_USER_SUCCESS = "REGISTER_USER_SUCCESS";
export const REGISTER_USER_FAIL = "REGISTER_USER_FAIL";

export const LOAD_USER_REQUEST = "LOAD_USER_REQUEST";
export const LOAD_USER_SUCCESS = "LOAD_USER_SUCCESS";
export const LOAD_USER_FAIL = "LOAD_USER_FAIL";

export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAIL = "LOGOUT_FAIL";

export const UPDATE_PROFILE_REQUEST = "UPDATE_PROFILE_REQUEST";
export const UPDATE_PROFILE_SUCCESS = "UPDATE_PROFILE_SUCCESS";
export const UPDATE_PROFILE_RESET = "UPDATE_PROFILE_RESET";
export const UPDATE_PROFILE_FAIL = "UPDATE_PROFILE_FAIL";

export const UPDATE_PASSWORD_REQUEST = "UPDATE_PASSWORD_REQUEST";
export const UPDATE_PASSWORD_SUCCESS = "UPDATE_PASSWORD_SUCCESS";
export const UPDATE_PASSWORD_RESET = "UPDATE_PASSWORD_RESET";
export const UPDATE_PASSWORD_FAIL = "UPDATE_PASSWORD_FAIL";

export const FORGOT_PASSWORD_REQUEST = "FORGOT_PASSWORD_REQUEST";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

export const RESET_PASSWORD_REQUEST = "RESET_PASSWORD_REQUEST";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

export const ALL_USERS_REQUEST = "ALL_USERS_REQUEST";
export const ALL_USERS_SUCCESS = "ALL_USERS_SUCCESS";
export const ALL_USERS_FAIL = "ALL_USERS_FAIL";

export const USER_DETAILS_REQUEST = "USER_DETAILS_REQUEST";
export const USER_DETAILS_SUCCESS = "USER_DETAILS_SUCCESS";
export const USER_DETAILS_FAIL = "USER_DETAILS_FAIL";

export const UPDATE_USER_REQUEST = "UPDATE_USER_REQUEST";
export const UPDATE_USER_SUCCESS = "UPDATE_USER_SUCCESS";
export const UPDATE_USER_RESET = "UPDATE_USER_RESET";
export const UPDATE_USER_FAIL = "UPDATE_USER_FAIL";

export const DELETE_USER_REQUEST = "DELETE_USER_REQUEST";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";
export const DELETE_USER_FAIL = "DELETE_USER_FAIL";
export const DELETE_USER_RESET = "DELETE_USER_RESET";

export const LOGO_UPDATED_REQUEST = "LOGO_UPDATED_REQUEST";
export const LOGO_UPDATED_SUCCESS = "LOGO_UPDATED_SUCCESS";
export const LOGO_UPDATED_RESET = "LOGO_UPDATED_RESET";
export const LOGO_UPDATED_FAIL = "LOGO_UPDATED_FAIL";

export const GET_LOGO_REQUEST = "GET_LOGO_REQUEST";
export const GET_LOGO_SUCCESS = "GET_LOGO_SUCCESS";
export const GET_LOGO_FAIL = "GET_LOGO_FAIL";

export const GET_LOGO_ADMIN_REQUEST = "GET_LOGO_ADMIN_REQUEST";
export const GET_LOGO_ADMIN_SUCCESS = "GET_LOGO_ADMIN_SUCCESS";
export const GET_LOGO_ADMIN_FAIL = "GET_LOGO_ADMIN_FAIL";

export const UPLOAD_LOGO_REQUEST = "UPLOAD_LOGO_REQUEST";
export const UPLOAD_LOGO_SUCCESS = "UPLOAD_LOGO_SUCCESS";
export const UPLOAD_LOGO_RESET = "UPLOAD_LOGO_RESET";
export const UPLOAD_LOGO_FAIL = "UPLOAD_LOGO_FAIL";

export const DELETE_LOGO_REQUEST = "DELETE_LOGO_REQUEST";
export const DELETE_LOGO_SUCCESS = "DELETE_LOGO_SUCCESS";
export const DELETE_LOGO_RESET = "DELETE_LOGO_RESET";
export const DELETE_LOGO_FAIL = "DELETE_LOGO_FAIL";

export const DETAILS_LOGO_REQUEST = "DELETE_LOGO_REQUEST";
export const DETAILS_LOGO_SUCCESS = "DELETE_LOGO_SUCCESS";
export const DETAILS_LOGO_FAIL = "DELETE_LOGO_FAIL";

export const UPLOAD_BANNER_REQUEST = "UPLOAD_BANNER_REQUEST";
export const UPLOAD_BANNER_SUCCESS = "UPLOAD_BANNER_SUCCESS";
export const UPLOAD_BANNER_RESET = "UPLOAD_BANNER_RESET";
export const UPLOAD_BANNER_FAIL = "UPLOAD_BANNER_FAIL";

export const BANNER_UPDATED_REQUEST = "BANNER_UPDATED_REQUEST";
export const BANNER_UPDATED_SUCCESS = "BANNER_UPDATED_SUCCESS";
export const BANNER_UPDATED_RESET = "BANNER_UPDATED_RESET";
export const BANNER_UPDATED_FAIL = "BANNER_UPDATED_FAIL";

export const GET_BANNER_REQUEST = "GET_BANNER_REQUEST";
export const GET_BANNER_SUCCESS = "GET_BANNER_SUCCESS";
export const GET_BANNER_FAIL = "GET_BANNER_FAIL";

export const GET_BANNER_ADMIN_REQUEST = "GET_BANNER_ADMIN_REQUEST";
export const GET_BANNER_ADMIN_SUCCESS = "GET_BANNER_ADMIN_SUCCESS";
export const GET_BANNER_ADMIN_FAIL = "GET_BANNER_ADMIN_FAIL";

export const DELETE_BANNER_REQUEST = "DELETE_BANNER_REQUEST";
export const DELETE_BANNER_SUCCESS = "DELETE_BANNER_SUCCESS";
export const DELETE_BANNER_RESET = "DELETE_BANNER_RESET";
export const DELETE_BANNER_FAIL = "DELETE_BANNER_FAIL";

export const DETAILS_BANNER_REQUEST = "DETAILS_BANNER_REQUEST";
export const DETAILS_BANNER_SUCCESS = "DETAILS_BANNER_SUCCESS";
export const DETAILS_BANNER_FAIL = "DETAILS_BANNER_FAIL";

export const CLEAR_ERRORS = "CLEAR_ERRORS";