import React,{useRef,useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../components/Loader.js";
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css/pagination";
// import { Pagination } from "swiper";
import { getAllCategories } from "../actions/categoryAction";
import { getProduct } from "../actions/productAction";

// Import Swiper styles
// import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade,Autoplay,Navigation,Pagination,FreeMode,Thumbs } from "swiper";
const Contact = () => {

    const dispatch = useDispatch();
    const {  categories } = useSelector((state) => state.categories);
    const { loading, error, products } = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAllCategories());
        
    }, [dispatch, error, alert]);

   

	return (
		<>
        {
            loading? ( <Loader />) :(

               <>

			{/*<!-- Page Banner Section Start -->*/}
    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    <div className="overlayBanner"></div>
        <div className="container">
            {/*<!-- Page Banner Content End -->*/}
            <div className="page-banner-content">
                <h2 className="title">Contact Us</h2>

                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li className="active">Contact Us</li>
                </ul>
            </div>
            {/*<!-- Page Banner Content End -->*/}
        </div>
    </div>
    {/*<!-- Page Banner Section End -->*/}

    {/*<!-- Contact Section Start -->*/}
    <div className="section section-padding">
        <div className="container">
            {/*<!-- Contact Wrapper Start -->*/}
            <div className="contact-wrapper">
                <div className="row gx-0">
                    <div className="col-lg-4">
                        <div className="contact-info">
                            <h2 className="title">Contact Us</h2>
                            <h4 className="mt-3">
                                Tiles and Flooring Shop
                            </h4>

                            {/*<!-- Contact Info Items Start -->*/}
                            <div className="contact-info-items">
                                <div className="single-contact-info">
                                    <div className="info-icon">
                                        <i className="pe-7s-call"></i>
                                    </div>
                                    <div className="info-content">
                                        <p>
                                            <Link to="tel:+447951488615">+44 7951 488615</Link>
                                        </p>
                                    </div>
                                </div>

                                <div className="single-contact-info">
                                    <div className="info-icon">
                                        <i className="pe-7s-mail"></i>
                                    </div>
                                    <div className="info-content">
                                        <p>
                                            <Link to="mailto:info@tilesandfloording.shop">info@tilesandfloording.shop</Link>
                                        </p>
                                    </div>
                                </div>

                                <div className="single-contact-info">
                                    <div className="info-icon">
                                        <i className="pe-7s-map-marker"></i>
                                    </div>
                                    <div className="info-content">
                                        <p>1 Concord Business Centre, Concord Road, London , W3 0TJ</p>
                                    </div>
                                </div>
                            </div>
                            {/*<!-- Contact Info Items End -->*/}

                            {/*<!-- Contact Social Start -->*/}
                            <ul className="social">
                                <li>
                                    <Link to="#"><i className="fa fa-twitter"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-tumblr"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-facebook-f"></i></Link>
                                </li>
                                <li>
                                    <Link to="#"><i className="fa fa-instagram"></i></Link>
                                </li>
                            </ul>
                            {/*<!-- Contact Social End -->*/}

                            <img src="../assets/images/contact-info.png" alt="Contact-info" />
                        </div>
                    </div>
                    <div className="col-lg-8">
                        {/*<!-- Contact Form Start  -->*/}
                        <div className="contact-form">
                            <form id="contact-form">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="text" name="name" placeholder="Name*"  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="email" name="email" placeholder="Email*"  />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="text" name="subject" placeholder="Subject" />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="single-form">
                                            <input type="text" name="phone" placeholder="Phone No" />
                                        </div>
                                    </div>
                                    <div className="col-md-12">
                                        <div className="single-form">
                                            <textarea name="message" placeholder="Write your comments here" ></textarea>
                                        </div>
                                    </div>
                                    <p className="form-message"></p>
                                    <div className="col-md-12">
                                        <div className="single-form">
                                            <button type="submit" className="btn btn-dark btn-hover-primary">
                                                Submit Review
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/*<!-- Contact Form End  -->*/}
                    </div>
                </div>
            </div>
            {/*<!-- Contact Wrapper End -->*/}
        </div>
    </div>
    {/*<!-- Contact Section End -->*/}

    {/*<!-- Contact Map Start -->*/}
    <div className="contact-map">
    <iframe id="gmap_canvas" src="https://www.google.com/maps/embed?pb=!1m14!1m12!1m3!1d2482.4394227262273!2d-0.27993642405802177!3d51.523499709527954!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!5e0!3m2!1sen!2s!4v1686567370559!5m2!1sen!2s" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
    </div>
    {/*<!-- Contact Map End -->*/}
    <div className="section bg-color-01">
        <div className="products-banner products-banner-active">
            <div className="swiper-container">
            <Swiper
                slidesPerView={2}
                spaceBetween={0}
                navigation={false}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
                modules={[ Navigation ]}
                className="mySwiper"
              >
              {/*{categories && categories.slice(0,4).map((item) => (
                                <li key={item._id} className={splitLocation[1] === `shop/category/${item.name}` ? "active" : ``}>
                                    <Link to={`/shop/category/${item.name}`}>{item.name}</Link>
                                </li>
                            ))}*/}
            {categories && categories.map((item) => (
                <SwiperSlide key={item._id}>
                    <div className="swiper-slide">
                        {/*<!-- Single Products Banner Start -->*/}
                        <div className="single-products-banner">
                            <img src={item.images[0]?.url} width="480" height="600" alt="Category" />

                            <div className="products-banner-content">
                                <div className="banner-content-wrapper">
                                    <h4 className="title">
                                        <Link className="text-capitalize" to={`/shop/category/${item.name}`}>{item.name}</Link>
                                    </h4>
                                    <span className="products-count"
                                            >{products.filter(product => product.category === item.name).length} Products</span>
                                    <Link to={`/shop/category/${item.name}`} className="arrow"><i className="pe-7s-angle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/*<!-- Single Products Banner End -->*/}
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
                
            </div>
        </div>
    </div>
    </>
     )
        }
			
		</>
	)
}

export default Contact