import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_RESET,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  DELETE_USER_RESET,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  UPDATE_USER_RESET,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  UPLOAD_LOGO_REQUEST,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_RESET,
  UPLOAD_LOGO_FAIL,
  GET_LOGO_REQUEST,
  GET_LOGO_SUCCESS,
  GET_LOGO_FAIL,
  GET_LOGO_ADMIN_REQUEST,
  GET_LOGO_ADMIN_SUCCESS,
  GET_LOGO_ADMIN_FAIL,
  DELETE_LOGO_REQUEST,
  DELETE_LOGO_SUCCESS,
  DELETE_LOGO_FAIL,
  DELETE_LOGO_RESET,
  DETAILS_LOGO_REQUEST,
  DETAILS_LOGO_SUCCESS,
  DETAILS_LOGO_FAIL,
  LOGO_UPDATED_REQUEST,
  LOGO_UPDATED_SUCCESS,
  LOGO_UPDATED_RESET,
  LOGO_UPDATED_FAIL,
  UPLOAD_BANNER_REQUEST,
  UPLOAD_BANNER_SUCCESS,
  UPLOAD_BANNER_RESET,
  UPLOAD_BANNER_FAIL,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  GET_BANNER_ADMIN_REQUEST,
  GET_BANNER_ADMIN_SUCCESS,
  GET_BANNER_ADMIN_FAIL,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  DELETE_BANNER_RESET,
  DETAILS_BANNER_REQUEST,
  DETAILS_BANNER_SUCCESS,
  DETAILS_BANNER_FAIL,
  BANNER_UPDATED_REQUEST,
  BANNER_UPDATED_SUCCESS,
  BANNER_UPDATED_RESET,
  BANNER_UPDATED_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstant";

export const userReducer = (state = { user: {} }, action) => {

	switch (action.type) {
		case LOGIN_REQUEST:
		case REGISTER_USER_REQUEST:
		case LOAD_USER_REQUEST:
			return{
				loading: true,
				isAuthenticated: false,
			};

		case LOGIN_SUCCESS:
    case REGISTER_USER_SUCCESS:
		case LOAD_USER_SUCCESS:
			return{
				...state,
				loading: false,
				isAuthenticated: true,
				user: action.payload,
			};

		case LOGOUT_SUCCESS:
      return {
        loading: false,
        user: null,
        isAuthenticated: false,
      };

		case LOGIN_FAIL:
    case REGISTER_USER_FAIL:
			return{
				...state,
				loading: false,
				isAuthenticated: false,
				user: null,
				error: action.payload,
			};

		case LOAD_USER_FAIL:
      return {
        loading: false,
        isAuthenticated: false,
        user: null,
        error: action.payload,
      };

    case LOGOUT_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

		case CLEAR_ERRORS:
			return{
		        ...state,
		        error: null,
		    }

		default:
			return state;	
	}
};

export const getLogoReducer = (state = { logos: [] },action) =>{

  switch (action.type) {
    case GET_LOGO_ADMIN_REQUEST:
    case GET_LOGO_REQUEST:
      return{
        loading: true,
            logos: [],
      }
    case GET_LOGO_SUCCESS:
      return{
        loading: false,
            logos: action.payload,
      }
    case GET_LOGO_ADMIN_SUCCESS:
      return{
        loading: false,
            logos: action.payload,
      }
    case GET_LOGO_ADMIN_FAIL:
    case GET_LOGO_FAIL:
      return{
        loading: false,
            error: action.payload,
      }
    case CLEAR_ERRORS:
      return{
            ...state,
            error: null,
        }
    default:
      return state; 
  }

};

export const getBannerReducer = (state = { banners: [] },action) =>{

  switch (action.type) {
    case GET_BANNER_ADMIN_REQUEST:
    case GET_BANNER_REQUEST:
      return{
        loading: true,
            banners: [],
      }
    case GET_BANNER_SUCCESS:
      return{
        loading: false,
            banners: action.payload,
      }
    case GET_BANNER_ADMIN_SUCCESS:
      return{
        loading: false,
            banners: action.payload,
      }
    case GET_BANNER_ADMIN_FAIL:
    case GET_BANNER_FAIL:
      return{
        loading: false,
            error: action.payload,
      }
    case CLEAR_ERRORS:
      return{
            ...state,
            error: null,
        }
    default:
      return state; 
  }

};

export const logoReducer = (state = { newlogo: {} }, action) => {

  switch (action.type) {
    case UPLOAD_LOGO_REQUEST:
      return{
        ...state,
        loading: true,
      };
    case UPLOAD_LOGO_SUCCESS:
      return{
        loading: false,
        success: action.payload.success,
        newlogo: action.payload.newlogo,
      };
    case UPLOAD_LOGO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_LOGO_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return{
            ...state,
            error: null,
        }

    default:
      return state; 
  }
};

export const bannerReducer = (state = { newbanner: {} }, action) => {

  switch (action.type) {
    case UPLOAD_BANNER_REQUEST:
      return{
        ...state,
        loading: true,
      };
    case UPLOAD_BANNER_SUCCESS:
      return{
        loading: false,
        success: action.payload.success,
        newbanner: action.payload.newbanner,
      };
    case UPLOAD_BANNER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_BANNER_RESET:
      return {
        ...state,
        success: false,
      };
    case CLEAR_ERRORS:
      return{
            ...state,
            error: null,
        }

    default:
      return state; 
  }
};

export const profileReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_PROFILE_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
    case UPDATE_USER_REQUEST:
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PROFILE_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case UPDATE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };

    case DELETE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload.success,
        message: action.payload.message,
      };

    case UPDATE_PROFILE_FAIL:
    case UPDATE_PASSWORD_FAIL:
    case UPDATE_USER_FAIL:
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPDATE_PROFILE_RESET:
    case UPDATE_PASSWORD_RESET:
    case UPDATE_USER_RESET:
      return {
        ...state,
        isUpdated: false,
      };
      
    case DELETE_USER_RESET:
      return {
        ...state,
        isDeleted: false,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const forgotPasswordReducer = (state = {}, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_REQUEST:
    case RESET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
      
    case FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        message: action.payload,
      };

    case RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        loading: false,
        success: action.payload,
      };

    case FORGOT_PASSWORD_FAIL:
    case RESET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const allUsersReducer = (state = { users: [] }, action) => {
  switch (action.type) {
    case ALL_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ALL_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        users: action.payload,
      };

    case ALL_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const userDetailsReducer = (state = { user: {} }, action) => {
  switch (action.type) {
    case USER_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case USER_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case USER_DETAILS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };

    default:
      return state;
  }
};

export const singleLogoReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_LOGO_REQUEST:
    case LOGO_UPDATED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LOGO_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };

    case LOGO_UPDATED_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_LOGO_FAIL:
    case LOGO_UPDATED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_LOGO_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case LOGO_UPDATED_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const singleBannerReducer = (state = {}, action) => {
  switch (action.type) {
    case DELETE_BANNER_REQUEST:
    case BANNER_UPDATED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_BANNER_SUCCESS:
      return {
        ...state,
        loading: false,
        isDeleted: action.payload,
      };
    case BANNER_UPDATED_SUCCESS:
      return {
        ...state,
        loading: false,
        isUpdated: action.payload,
      };
    case DELETE_BANNER_FAIL:
    case BANNER_UPDATED_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_BANNER_RESET:
      return {
        ...state,
        isDeleted: false,
      };
    case BANNER_UPDATED_RESET:
      return {
        ...state,
        isUpdated: false,
      };
    case CLEAR_ERRORS:
      return {
        ...state,
        error: null,
      };
    default:
      return state;
  }
};

export const logoDetailsReducer = (state = { logo: {} },action) =>{

  switch (action.type) {
    case DETAILS_LOGO_REQUEST:
      return{
        loading: true,
        ...state,
      }
    case  DETAILS_LOGO_SUCCESS:
      return{
        loading: false,
        logo: action.payload,
      }
    case  DETAILS_LOGO_FAIL:
      return{
        loading: false,
        error: action.payload,
      }
    case CLEAR_ERRORS:
      return{
            ...state,
            error: null,
        }
    default:
      return state; 
  }

};

export const bannerDetailsReducer = (state = { banner: {} },action) =>{

  switch (action.type) {
    case DETAILS_BANNER_REQUEST:
      return{
        loading: true,
        ...state,
      }
    case  DETAILS_BANNER_SUCCESS:
      return{
        loading: false,
        banner: action.payload,
      }
    case  DETAILS_BANNER_FAIL:
      return{
        loading: false,
        error: action.payload,
      }
    case CLEAR_ERRORS:
      return{
            ...state,
            error: null,
        }
    default:
      return state; 
  }

};