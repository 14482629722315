import React from 'react';
import {Link} from 'react-router-dom';

const ReturnPolicy = () => {
	return (
		<>
			{/*<!-- Page Banner Section Start -->*/}
		    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    		<div className="overlayBanner"></div>
		        <div className="container">
		            {/*<!-- Page Banner Content End -->*/}
		            <div className="page-banner-content">
		                <h2 className="title">Return Policy</h2>

		                <ul className="breadcrumb">
		                    <li><Link to="/">Home</Link></li>
		                    <li className="active">Return Policy</li>
		                </ul>
		            </div>
		            {/*<!-- Page Banner Content End -->*/}
		        </div>
		    </div>
		    {/*<!-- Page Banner Section End -->*/}	


		    {/*<!-- Contact Section Start -->*/}
		    <div className="section section-padding">
		        <div className="container">
		            {/*<!-- Contact Wrapper Start -->*/}
		            		
		            	<h3 className="mb-2">Return Policy</h3>
            	<p>Our return policy requires you to return any goods to us in a re-saleable condition, in their
original carton/box, and within 30 days of delivery. Please note that the range of goods must still
be available for sale on our website.</p>
					<p>
						If you receive damaged or incorrect tiles, please inform the Tiles and Flooring Shop (TFS)
within two working days of delivery. Ensure to provide an image of the items while they are still
on the pallet. We will make every effort to send replacements or issue a refund if applicable.
					</p>

					<p>
					Please be aware that there will be a restocking charge of 10% for the returned goods. It is your
responsibility to organise the return of the goods to TFS. Once we receive the re-saleable items,
we will process your refund within 3-5 working days.
					</p>

					<p>
						We recommend securely fastening the tiles to the original pallet to prevent any movement during
transit. If there is a possibility of transit damage, take photographs before and after wrapping the
tiles. Due to batching issues, we accept returns only within 30 days of delivery. The original
delivery costs are none refundable.
					</p>

					<p>
						TFS require clear images of each damaged tile or a picture showing multiple damaged tiles to
assess the number of damaged tiles claimed. If necessary, our customer service team may request
additional images or refuse to provide replacements or a refund until the correct information is
provided.
					</p>

					<p>
					Please remember that we will process the refund only after we receive and inspect the returned
goods. Also, ensure the package includes the date, time, and signature to expedite the process.
Once we have received the items, we will thoroughly check all the goods, as most of the tiles on
our website are sold individually, and proceed with your refund accordingly.
					</p>

		            {/*<!-- Contact Wrapper End -->*/}
		        </div>
		    </div>
		    {/*<!-- Contact Section End -->*/}


		</>
	)
}

export default ReturnPolicy