import React from 'react';
import { Link } from "react-router-dom";
import { TreeView, TreeItem } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AddIcon from "@material-ui/icons/Add";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import ListAltIcon from "@material-ui/icons/ListAlt";
import DashboardIcon from "@material-ui/icons/Dashboard";
import PeopleIcon from "@material-ui/icons/People";
import RateReviewIcon from "@material-ui/icons/RateReview";

const Sidebar = () => {
	return (
		<>
			<div className="col-lg-3 col-md-4">
			<div className="sidebar">

		      <Link to="/admin/dashboard">
		        <p>
		          <DashboardIcon /> Dashboard
		        </p>
		      </Link>
		      <Link>
		        <TreeView
		          defaultCollapseIcon={<ExpandMoreIcon />}
		          defaultExpandIcon={<ImportExportIcon />}
		        >
		          <TreeItem nodeId="1" label="Logo">
		            <Link to="/admin/logo-list">
		              <TreeItem nodeId="3" label="All Logos" icon={<PostAddIcon />} />
		            </Link>

		            <Link to="/admin/logo">
		              <TreeItem nodeId="2" label="Add" icon={<AddIcon />} />
		            </Link>
		          </TreeItem>
		        </TreeView>
		      </Link>
		      <Link>
		        <TreeView
		          defaultCollapseIcon={<ExpandMoreIcon />}
		          defaultExpandIcon={<ImportExportIcon />}
		        >
		          <TreeItem nodeId="1" label="Banner">
		            <Link to="/admin/banner-list">
		              <TreeItem nodeId="3" label="All Banners" icon={<PostAddIcon />} />
		            </Link>

		            <Link to="/admin/create-banner">
		              <TreeItem nodeId="2" label="Add" icon={<AddIcon />} />
		            </Link>
		          </TreeItem>
		        </TreeView>
		      </Link>
		      <Link>
		        <TreeView
		          defaultCollapseIcon={<ExpandMoreIcon />}
		          defaultExpandIcon={<ImportExportIcon />}
		        >
		          <TreeItem nodeId="1" label="Products">
		            <Link to="/admin/products">
		              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
		            </Link>

		            <Link to="/admin/create-product">
		              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
		            </Link>
		          </TreeItem>
		        </TreeView>
		      </Link>
		      <Link>
		        <TreeView
		          defaultCollapseIcon={<ExpandMoreIcon />}
		          defaultExpandIcon={<ImportExportIcon />}
		        >
		          <TreeItem nodeId="1" label="Categories">
		            <Link to="/admin/categories">
		              <TreeItem nodeId="2" label="All" icon={<PostAddIcon />} />
		            </Link>

		            <Link to="/admin/create-category">
		              <TreeItem nodeId="3" label="Create" icon={<AddIcon />} />
		            </Link>
		          </TreeItem>
		        </TreeView>
		      </Link>
		      <Link to="/admin/orders">
		        <p>
		          <ListAltIcon />
		          Orders
		        </p>
		      </Link>
		      <Link to="/admin/users">
		        <p>
		          <PeopleIcon /> Users
		        </p>
		      </Link>
		      <Link to="/admin/reviews">
		        <p>
		          <RateReviewIcon />
		          Reviews
		        </p>
		      </Link>

		    </div>
		    </div>
		</>
	)
}

export default Sidebar