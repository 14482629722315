import React,{useRef,useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../components/Loader.js";
import { Modal } from 'react-bootstrap';
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css/pagination";
// import { Pagination } from "swiper";
import { getAllCategories } from "../actions/categoryAction";
import { getProduct } from "../actions/productAction";
import blogs from '../blogs';
// Import Swiper styles
// import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade,Autoplay,Navigation,Pagination,FreeMode,Thumbs } from "swiper";

export const shortenText = (text, n) => {
  if (text.length > n) {
    const shoretenedText = text.substring(0, n).concat("...");
    return shoretenedText;
  }
  return text;
};

const Blogs = () => {

    const [ViewFirstBlogModel, SetFirstBlogModel] = useState(false)
    const handleFirstBlogShow = () => { SetFirstBlogModel(true) }
    const hanldeFirstBlogClose = () => { SetFirstBlogModel(false) }
    const [ViewSecondBlogModel, SetSecondBlogModel] = useState(false)
    const handleSecondBlogShow = () => { SetSecondBlogModel(true) }
    const hanldeSecondBlogClose = () => { SetSecondBlogModel(false) }
    const [ViewThirdBlogModel, SetThirdBlogModel] = useState(false)
    const handleThirdBlogShow = () => { SetThirdBlogModel(true) }
    const hanldeThirdBlogClose = () => { SetThirdBlogModel(false) }
    const dispatch = useDispatch();
    const {  categories } = useSelector((state) => state.categories);
    const { loading, error, products } = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAllCategories());
        
    }, [dispatch, error, alert]);

    const scrollToTop = () =>{
        window.scrollTo({
          top: 0, 
          behavior: 'smooth'
          /* you can also use 'auto' behaviour
             in place of 'smooth' */
        });
    };

    // const filteredProducts = products.filter(product => product.category === categories);
    // console.log(categories.map(item => item.name));

	return (
		<>
        {
            loading? ( <Loader />) :(

               <>

			{/*<!-- Page Banner Section Start -->*/}
    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    <div className="overlayBanner"></div>
        <div className="container">
            {/*<!-- Page Banner Content End -->*/}
            <div className="page-banner-content">
                <h2 className="title">Blogs</h2>

                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li className="active">Blogs</li>
                </ul>
            </div>
            {/*<!-- Page Banner Content End -->*/}
        </div>
    </div>
    {/*<!-- Page Banner Section End -->*/}

    {/*<!-- Blog Section Start -->*/}
    <div className="section section-padding custom_blogs">
        <div className="container">
            {/*<!-- Blog Wrapper Start -->*/}
            <div className="blog-wrapper">
                <div className="row">
                {
                    blogs.map((item)=>{
                        let name = item.name;
                        name = name.replace(/\s+/g, '-');
                        const url = `/blog/${name}`;
                        return(
                            <div className="col-lg-4 col-md-6" key={item.id}>
                                {/*<!-- Single Blog Start -->*/}
                                <div className="single-blog">
                                    <Link to={url} onClick={scrollToTop}><img src={item.image} width="370" height="230" alt="Blog" /></Link>

                                    <div className="blog-content">
                                        <ul className="blog-meta">
                                            <li>
                                                <i className="pe-7s-user"></i>
                                                <span>
                                                        BRAND:<Link to="/"><b>TFS</b></Link></span>
                                            </li>
                                            <li>
                                                <i className="pe-7s-date"></i>
                                                <span>{item.date}</span>
                                            </li>
                                        </ul>
                                        <h4 className="title">
                                            <Link to={url} onClick={scrollToTop}>{shortenText(item.title,50)} </Link>
                                        </h4>
                                        <Link to={url} onClick={scrollToTop} className="btn btn-dark btn-hover-primary">Read More</Link>
                                    </div>
                                </div>
                                {/*<!-- Single Blog End -->*/}
                            </div>
                        )
                    })
                }

                    

                    {/*<div className="col-lg-4 col-md-6">
                        <!-- Single Blog Start -->
                        <div className="single-blog">
                            <Link onClick={() => { handleSecondBlogShow() }}><img src="../../assets/images/blog2.webp" width="370" height="230" alt="Blog" /></Link>

                            <div className="blog-content">
                                <ul className="blog-meta">
                                    <li>
                                        <i className="pe-7s-user"></i>
                                        <span>
                                                BRAND:<Link to="/"><b>TFS</b></Link></span>
                                    </li>
                                    <li>
                                        <i className="pe-7s-date"></i>
                                        <span>14 JULY 2023</span>
                                    </li>
                                </ul>
                                <h4 className="title">
                                    <Link onClick={() => { handleSecondBlogShow() }}>The Pros and Cons of Different Flooring Materials: </Link>
                                </h4>
                                <Link onClick={() => { handleSecondBlogShow() }} className="btn btn-dark btn-hover-primary">Read More</Link>
                            </div>
                        </div>
                        <!-- Single Blog End -->
                    </div>*/}

                    {/*<div className="col-lg-4 col-md-6">
                        <!-- Single Blog Start -->
                        <div className="single-blog">
                            <Link onClick={() => { handleThirdBlogShow() }}><img src="../../assets/images/blog3.webp" width="370" height="230" alt="Blog" /></Link>

                            <div className="blog-content">
                                <ul className="blog-meta">
                                    <li>
                                        <i className="pe-7s-user"></i>
                                        <span>
                                                BRAND:<Link to="/"><b>TFS</b></Link></span>
                                    </li>
                                    <li>
                                        <i className="pe-7s-date"></i>
                                        <span>14 JULY 2023</span>
                                    </li>
                                </ul>
                                <h4 className="title">
                                    <Link onClick={() => { handleThirdBlogShow() }}>The Top Tile Trends of 2023: Stay Ahead of the Curve </Link>
                                </h4>
                                <Link onClick={() => { handleThirdBlogShow() }} className="btn btn-dark btn-hover-primary">Read More</Link>
                            </div>
                        </div>
                        <!-- Single Blog End -->
                    </div> */}                  
                    
                </div>
            </div>
            {/*<!-- Blog Wrapper End -->*/}

            
        </div>
    </div>
    {/*<!-- Blog Section End -->*/}

    
    <div className="section bg-color-01">
        <div className="products-banner products-banner-active">
            <div className="swiper-container">
            <Swiper
                slidesPerView={2}
                spaceBetween={0}
                navigation={false}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
                modules={[ Navigation ]}
                className="mySwiper"
              >
              {/*{categories && categories.slice(0,4).map((item) => (
                                <li key={item._id} className={splitLocation[1] === `shop/category/${item.name}` ? "active" : ``}>
                                    <Link to={`/shop/category/${item.name}`}>{item.name}</Link>
                                </li>
                            ))}*/}
            {categories && categories.map((item) => (
                <SwiperSlide key={item._id}>
                    <div className="swiper-slide">
                        {/*<!-- Single Products Banner Start -->*/}
                        <div className="single-products-banner">
                            <img src={item.images[0]?.url} width="480" height="600" alt="Category" />

                            <div className="products-banner-content">
                                <div className="banner-content-wrapper">
                                    <h4 className="title">
                                        <Link className="text-capitalize" to={`/shop/category/${item.name}`}>{item.name}</Link>
                                    </h4>
                                    <span className="products-count"
                                            >
                                            {/*{filteredProducts && filteredProducts.length} Products</span>*/}
                                            {products.filter(product => product.category === item.name).length} Products</span>
                                    <Link to={`/shop/category/${item.name}`} className="arrow"><i className="pe-7s-angle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/*<!-- Single Products Banner End -->*/}
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
                
            </div>
        </div>
    </div>
    </>
     )
        }

        {/*<!-- Modal -->*/}
        {/*<Modal show={ViewFirstBlogModel}
                onHide={hanldeFirstBlogClose}
                backdrop="static"
                keyboard={false}
                className="blogModel"
                >
            <div className="blog_detail_image text-center">
                <img src="../../assets/images/blog1.webp" width="770" height="480" alt="Blog" />
            </div>
            <div className="blog_detail_content">
                <h4>Transform Your Space: The Ultimate Guide to Choosing the Perfect Tiles and Flooring</h4>
                <p>
                When it comes to transforming your living space, few things have as much impact as the choice of tiles and flooring. The proper selection can completely change the look and feel of a room, enhancing its aesthetics and functionality.
                </p>
                <p>
                In the UK, a wide range of options are available for tiles and flooring, making it essential to understand the factors to consider before making your decision. This comprehensive guide will explore five key aspects to help you choose the perfect tiles and flooring for your space in the UK.
                </p>
                <h4>Understanding Your Space and Style</h4>
                <p>
                Before diving into the world of tiles and flooring options, assessing your space and determining your preferred style is crucial. Consider factors such as the size and layout of the room, the amount of natural light, and existing architectural features. By understanding your space, you can make more informed decisions regarding the type of tiles and flooring that will complement the room's overall aesthetic.
                </p>
                <p>
                Regarding style, the UK offers a diverse range of design influences. From traditional British styles to contemporary and minimalist designs, understanding your personal style preferences will help you narrow down your choices and ensure the tiles and flooring align with your overall vision.
                </p>
                <h4>Types of Tiles and Flooring</h4>
                <p>
                The UK market offers an array of tiles and flooring materials to suit various needs and preferences. From classic options like ceramic and porcelain tiles to trendy choices like luxury vinyl planks and engineered hardwood, there are countless options to explore.
                Ceramic tiles are a popular choice due to their versatility and affordability. They come in various sizes, colours, and patterns, making them suitable for different areas of the home. Porcelain tiles, on the other hand, are known for their durability and water resistance, making them ideal for bathrooms and kitchens.
                </p>
                <p>
                If you prefer wood's warmth and natural beauty, engineered hardwood or laminate flooring can be excellent alternatives. Luxury vinyl flooring is another popular option due to its resilience, ease of maintenance, and wide range of designs. It mimics the look of natural materials like wood or stone but is more affordable and durable.
                </p>
                <h4>Considering Practicality and Durability</h4>
                <p>
                    Practicality and durability are essential, especially in high-traffic areas or homes with pets and children. In such cases, choosing materials that can withstand wear and tear is crucial.
                    As mentioned earlier, porcelain tiles are highly durable and resistant to scratches and stains. They are also easy to clean, making them an excellent choice for areas that experience heavy foot traffic. Luxury vinyl flooring is another practical option, as it is resistant to moisture, scratches, and dents.
                </p>
                <p>
                    When it comes to durability, engineered hardwood and laminate flooring can provide a balance between aesthetics and practicality. It offers the look of natural wood but with added durability and resistance to moisture.
                </p>
                <h4>Exploring Design and Aesthetics</h4>
                <p>
                Design and aesthetics play a significant role in transforming the look and feel of any space. Tiles and flooring come in various colours, patterns, and textures, allowing you to create a unique and personalized atmosphere.
For a timeless look, neutral-coloured tiles and classic hardwood flooring can be an excellent choice. They provide a neutral backdrop that complements various design styles and allows for versatility in decor choices.
                </p>
                <p>
                If you prefer a more contemporary or vibrant look, explore the options of patterned tiles or bold-coloured luxury vinyl planks. These can add a touch of personality to a room and create a focal point.
Consider the overall theme of your space and explore the latest design trends in the UK to ensure your tiles and flooring choice aligns with your vision.
                </p>
                <h4>
                Budgeting for Tiles and Flooring
                </h4>
                <p>
                Setting a budget is crucial when selecting tiles and flooring. The UK market offers a wide price range, depending on the material, quality, and brand. Determining how much you are willing to spend and finding options that fit your budget is essential.
Consider the square footage of the area you wish to cover and research the average costs of different materials. Remember to account for additional expenses like installation, underlayment, and maintenance.
                </p>
                <p>
                By establishing a realistic budget, you can narrow your options and find the best value for your money without compromising quality.
                </p>
                <h4>
                Finding the Right Suppliers and Installers
                </h4>
                <p>
                Once you have decided on the type of tiles and flooring that best suit your space and budget, finding reliable suppliers and installers in the UK is essential. Look for reputable retailers or manufacturers with a wide selection of quality products.
Read customer reviews, ask for recommendations from friends or family, and request samples to see the quality and durability of the materials firsthand. Regarding installation, consider hiring professionals with experience handling the specific type of tiles or flooring you have chosen to ensure a flawless and long-lasting result.
                </p>
                <h4>Conclusion</h4>
                <p>
                Choosing the perfect tiles and flooring for your space in the UK requires careful consideration of factors such as space assessment, material options, practicality, design, budget, and reliable suppliers and installers. Following this guide, you can make an informed decision and transform your living space into a visually stunning and functional environment.
With countless options available, the UK market provides endless opportunities to create a space that reflects your style and enhances your everyday living experience. Take your time, explore different materials and designs, and enjoy the process of transforming your space with the perfect tiles and flooring.
                </p>
            </div>

            <div className="form-group text-end">
                <button onClick={hanldeFirstBlogClose} className="m-1 btn btn-secondary">Close</button>
            </div>
        </Modal>*/}

        {/*<!-- Modal -->*/}
        {/*<Modal show={ViewSecondBlogModel}
                onHide={hanldeSecondBlogClose}
                backdrop="static"
                keyboard={false}
                className="blogModel"
                >
            <div className="blog_detail_image text-center">
                <img src="../../assets/images/blog2.webp" width="770" height="480" alt="Blog" />
            </div>
            <div className="blog_detail_content">
                <h4>The Pros and Cons of Different Flooring Materials: Making the Right Choice for Your Space</h4>
                <p>
                Choosing the perfect flooring material for your space is a significant decision that can significantly impact your home or office's overall aesthetics, functionality, and maintenance requirements. With various options available, it can be overwhelming to determine which flooring material best suits your needs.
                </p>
                <p>
                This comprehensive guide will explore the pros and cons of various flooring materials, empowering you to make an informed decision. From the timeless elegance of tiles to the versatility of different flooring options, we will delve into critical aspects to consider when selecting the ideal flooring for your space.
                </p>
                <h4>Classic Elegance with Tiles</h4>
                <p>
                Tiles have been a popular flooring choice for centuries, known for their durability, versatility, and timeless appeal. Whether you opt for ceramic, porcelain, or natural stone tiles, they offer numerous benefits. Tiles are resistant to moisture, stains, and scratches, making them ideal for high-traffic areas such as kitchens and bathrooms. Moreover, they provide a wide range of design options, allowing you to create unique patterns and layouts that enhance the overall aesthetic of your space.
                </p>
                
                <h4>The Natural Beauty of Hardwood Flooring</h4>
                <p>
                Hardwood flooring exudes warmth, natural beauty, and timeless charm. It adds a touch of elegance to any space and complements various interior design styles. One of the primary advantages of hardwood flooring is its durability, as it can withstand heavy foot traffic and be sanded and refinished to address scratches and wear. However, hardwood floors are susceptible to moisture damage and require regular maintenance and care to retain their appearance.
                </p>
                
                <h4>Laminate Flooring: Affordability and Versatility Combined</h4>
                <p>
                   Laminate flooring offers an affordable alternative to hardwood, replicating the look of wood at a fraction of the cost. It is a synthetic material composed of multiple layers, including a high-resolution image of the wood grain. Laminate flooring is known for its durability, resistance to stains and fading, and ease of installation. However, it may possess a different level of authenticity and longevity than hardwood flooring.
                </p>
                
                <h4>The Practicality of Vinyl Flooring</h4>
                <p>
               Vinyl flooring has gained popularity due to its practicality and versatility. It is highly resistant to water, stains, and scratches, making it an excellent choice for moisture-prone areas, such as kitchens and bathrooms. Vinyl flooring comes in various styles, including luxury vinyl tiles (LVT), closely resembling natural materials like wood and stone. While vinyl flooring provides durability and affordability, it may need more premium appeal than other options.
               </p>
                <h4>
               The Comfort and Variety of Carpeting
                </h4>
                <p>
                    Carpeting adds comfort, warmth, and a cosy atmosphere to any space. It is available in various colours, textures, and patterns, allowing you to personalise your space according to your preferences. Carpeting offers sound absorption, insulation, and a soft underfoot feel, making it an ideal choice for bedrooms and living areas. However, carpets require regular cleaning and maintenance and may not be suitable for areas prone to spills or high humidity.
                </p>
                
                <h4>
               Modern Innovations: Engineered Wood Flooring
                </h4>
                <p>
                Engineered wood flooring combines the natural beauty of hardwood with enhanced durability. It consists of a natural wood veneer bonded to several layers of plywood or high-density fiberboard (HDF). Engineered wood is more resistant to moisture and temperature fluctuations than solid hardwood, making it suitable for areas where traditional hardwood, such as basements, may not be ideal. However, engineered wood costs can be higher than other flooring options.
                </p>
                <h4>Sustainability and Eco-Friendly Flooring Choices</h4>
                <p>
                    As environmental consciousness grows, many homeowners seek sustainable, eco-friendly flooring options. This subtopic explores materials such as bamboo, cork, and reclaimed wood. These flooring choices offer renewable resources, low environmental impact, and unique aesthetics. However, they may have specific maintenance requirements and limited design options compared to traditional flooring materials.
Selecting the ideal flooring material for your space requires careful consideration of factors such as durability, maintenance requirements, budget, and the desired aesthetic. Tiles offer timeless elegance, while hardwood flooring brings a touch of sophistication. Laminate and vinyl flooring provide versatility and affordability; carpeting adds warmth and comfort. Modern innovations like engineered wood combine durability and natural beauty.
                </p>
                <p>
                Furthermore, sustainable and eco-friendly flooring options cater to those seeking environmentally conscious choices. By weighing the pros and cons of each flooring material, you can make an informed decision that aligns with your needs, preferences, and overall decor.
                </p>
                <h4>The Bottom Line</h4>
                <p>
                    In summary, flooring material suits every space, whether you prioritise durability, aesthetics, budget-friendly options, or environmental sustainability. By understanding the advantages and disadvantages of different flooring materials, you can confidently select the perfect flooring that will enhance the beauty and functionality of your home or office.
                </p>
            </div>

            <div className="form-group text-end">
                <button onClick={hanldeSecondBlogClose} className="m-1 btn btn-secondary">Close</button>
            </div>
        </Modal>*/}

        {/*<!-- Modal -->*/}
        {/*<Modal show={ViewThirdBlogModel}
                onHide={hanldeThirdBlogClose}
                backdrop="static"
                keyboard={false}
                className="blogModel"
                >
            <div className="blog_detail_image text-center">
                <img src="../../assets/images/blog3.webp" width="770" height="480" alt="Blog" />
            </div>
            <div className="blog_detail_content">
                <h4>The Top Tile Trends of 2023: Stay Ahead of the Curve in Flooring Fashion</h4>
                <p>
                In the ever-evolving world of interior design, tile flooring is a popular choice among homeowners and designers alike. Not only does it offer durability and versatility, but it also allows for endless creativity and style possibilities.
                </p>
                <p>
                    In 2023, it's time to explore the top tile trends that will dominate the flooring fashion scene. From mesmerising patterns to bold colours, these trends will help you stay ahead of the curve and create a stunning statement in your home.
                </p>
                <h4>Embrace Geometric Designs for a Modern Twist</h4>
                <p>
                Geometric patterns have been significantly impacting tile flooring designs, and they are set to take centre stage in 2023. Embrace the beauty of hexagons, triangles, and intricate shapes to add a modern and artistic touch to your floors. 
Whether you opt for a single geometric pattern or mix and match different shapes, this trend will instantly elevate the visual appeal of any room. Geometric tiles can be used in various spaces, including bathrooms, kitchens, and living areas, providing a sleek and contemporary vibe.
                </p>
                
                <h4>Go Big and Bold with Oversized Tiles</h4>
                <p>
                    If you want to make a grand statement, oversized tiles are the way to go. In 2023, large-format tiles will be in high demand as they create a sense of expansiveness and elegance. These tiles are perfect for open-plan spaces, as they minimise grout lines and create a seamless look. Whether you choose large porcelain tiles or natural stone options, oversized tiles will bring a luxurious and sophisticated aesthetic to your home. Experiment with different textures and finishes to add depth and character to your flooring.
                </p>
                
                <h4>Elevate your Style with Mixed Material Tiles</h4>
                <p>
                  Mixing materials is a trend that has been gaining momentum in recent years, and it's here to stay in 2023. Combine different types of tiles, such as wood-look planks with ceramic or porcelain tiles, to create a visually captivating flooring design. This trend allows you to infuse warmth and texture into your space while maintaining the durability and easy maintenance of tile flooring. The juxtaposition of materials adds interest and uniqueness to any room, making it a perfect choice for those who seek a personalised and eclectic style.
                 </p>
                
                <h4>Get Creative with Artistic and Handcrafted Tiles</h4>
                <p>
                In 2023, artisanal and handcrafted tiles will be in the spotlight, adding an element of artistry and individuality to your floors. From hand-painted patterns to intricate mosaic designs, these tiles celebrate craftsmanship and creativity. Artistic tiles can be used as focal points, creating stunning feature areas in your home. Whether you opt for Moroccan-inspired zellige tiles or choose custom-made designs, these unique creations will make your flooring a work of art.
                </p>
                <h4>
               Conclusion
                </h4>
                <p>
                   As we venture into 2023, the tile flooring trends are about embracing creativity, pushing boundaries, and making bold statements. Whether you prefer geometric patterns, oversized tiles, mixed materials, or artisanal designs, there's a trend for everyone to incorporate into their home. The versatility and durability of tiles allow you to explore endless possibilities, transforming your floors into a captivating visual element. 
Stay ahead of the curve in flooring fashion by incorporating these top tile trends of 2023 and create a stylish and inviting space that reflects your style.
                </p>
                
            </div>

            <div className="form-group text-end">
                <button onClick={hanldeThirdBlogClose} className="m-1 btn btn-secondary">Close</button>
            </div>
        </Modal>*/}
			
		</>
	)
}

export default Blogs