import React from 'react';
import {Link} from 'react-router-dom';

const DeliveryInformation = () => {
	return (
		<>
			{/*<!-- Page Banner Section Start -->*/}
		    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    		<div className="overlayBanner"></div>
		        <div className="container">
		            {/*<!-- Page Banner Content End -->*/}
		            <div className="page-banner-content">
		                <h2 className="title">Delivery Information</h2>

		                <ul className="breadcrumb">
		                    <li><Link to="/">Home</Link></li>
		                    <li className="active">Delivery Information</li>
		                </ul>
		            </div>
		            {/*<!-- Page Banner Content End -->*/}
		        </div>
		    </div>
		    {/*<!-- Page Banner Section End -->*/}	


		    {/*<!-- Contact Section Start -->*/}
		    <div className="section section-padding">
		        <div className="container">
		            {/*<!-- Contact Wrapper Start -->*/}
		            		
		            	<h3 className="mb-2">Delivery Information</h3>
            	<p>You will get a text message from our carrier on the day of delivery, providing you with a 2-hour
window for the delivery. You will receive updates via text if there are any changes or delays.</p>
					<p>
						If you have any concerns about the condition of the delivery, please make sure to sign for the
goods as &quot;UNCHECKED&quot;.
					</p>

					<p>
					You must be available to accept the delivery on the requested date. Please do so to avoid re-
delivery charges.
					</p>

					<p>
						We cannot transport goods directly to a customer&#39;s property for insurance, health, and safety
reasons.
					</p>

					<p>
						We offer worldwide delivery service to most countries. Please contact us by telephone or email
with the details of your specific requirements.
					</p>

		            {/*<!-- Contact Wrapper End -->*/}
		        </div>
		    </div>
		    {/*<!-- Contact Section End -->*/}


		</>
	)
}

export default DeliveryInformation