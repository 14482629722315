import React,{useRef,useState,useEffect} from 'react';
import {Link} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../components/Loader.js";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css/pagination";
// import { Pagination } from "swiper";
import { getAllCategories } from "../actions/categoryAction";
import { clearErrors, getProduct } from "../actions/productAction";
// Import Swiper styles
// import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade,Autoplay,Navigation,Pagination,FreeMode,Thumbs } from "swiper";

const About = () => {

    const dispatch = useDispatch();
    const {  categories } = useSelector((state) => state.categories);
    const { loading, error, products } = useSelector((state) => state.products);

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAllCategories());
        
    }, [dispatch, error, alert]);

	return (
		<>

        {
            loading? ( <Loader />) :(

               <>
			
			{/*<!-- Page Banner Section Start -->*/}
    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    <div className="overlayBanner"></div>
        <div className="container">
            {/*<!-- Page Banner Content End -->*/}
            <div className="page-banner-content">
                <h2 className="title">About Us</h2>

                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li className="active">About Us</li>
                </ul>
            </div>
            {/*<!-- Page Banner Content End -->*/}
        </div>
    </div>
    {/*<!-- Page Banner Section End -->*/}

    {/*<!-- History Section Start -->*/}
    <div className="section section-padding-02">
        <div className="container">
            {/*<!-- History content End -->*/}
            <div className="history-content text-center">
                <div className="section-title-03">
                    {/*<h6 className="sub-title">Our History</h6>*/}
                    <h2 className="title" style={{textTransform : 'unset'}}>Tiles and Flooring Shop</h2>
                </div>

                <p>
                    <b>Welcome to Tiles and Flooring Shop</b>, the ultimate destination for all your tiles and flooring
needs. As a prominent and trusted tiles and flooring supplier operating from London, our
dedication is to provide an exceptional range of products and services to customers across the
UK.
                </p>
                <p>
                    At Tiles and Flooring Shop, you will discover an extensive selection of premium tiles that
cater to various styles and preferences. As a tile store, we take pride in curating a diverse
inventory that includes a wide range of materials, sizes, colours, and patterns.
Our tiles and flooring shop is committed to providing exceptional value to our customers. We
offer competitive pricing, discounts, and promotions on our extensive range of tiles and flooring
options. We aim to maintain high-quality standards for products for everyone without
compromising on quality or style. Whether working on a tight budget or seeking luxurious and
premium options, we have the right solution.
                </p>
                <p>
                    From elegant ceramic tiles and sophisticated porcelain tiles to the timeless beauty of natural
stone tiles, we offer a broader range of solutions to transform your spaces. We understand each
room has unique requirements, so we specialise in bathroom and kitchen tiles.
Our bathroom tiles combine functionality and aesthetics, allowing you to create a sanctuary that
reflects your style. With our kitchen tiles, you can achieve a harmonious blend of durability and
design, creating a culinary space that is both practical and visually stunning.
As a flooring supplier, we go beyond tiles to offer an impressive selection of flooring materials.
Our range includes hardwood, laminate, vinyl, and carpet options, providing a comprehensive
choice for every room in your home or office.
                </p>
                <p>
                    Whether you seek hardwood&#39;s warmth and elegance, laminate&#39;s versatility, vinyl&#39;s durability, or
carpet&#39;s plush comfort, our flooring collection has something for everyone.
We collaborate with experienced and skilled flooring contractors specialising in tile installation
and flooring solutions to ensure impeccable results. Our dedicated team possesses extensive
knowledge and expertise, allowing them to manage projects of any scale or complexity.
Our collaborated specialised contractors are well-versed in tile design, flooring design, and tile
patterns, ensuring a seamless and visually striking installation that exceeds your expectations.
In addition to our product offerings, we are committed to providing comprehensive maintenance
support for your tiles and flooring. Our website is a valuable resource for tile maintenance tips,
flooring maintenance advice, and practical installation guides. We understand the importance of
preserving the longevity and beauty of your investment, and our experts are always available to
offer personalised recommendations and assistance.
                </p>
                <p>
                    Our service&#39;s core is keeping up with the latest tile and flooring trends. Our website offers a
wealth of inspiration and ideas, featuring tile design ideas, flooring trends, and innovative
solutions to transform your spaces. By staying ahead of the curve, we ensure that our customers
can access the most up-to-date designs and materials, allowing them to create contemporary and
timeless spaces.
Our dedication to customer satisfaction extends to both residential and commercial projects.
Whether you are renovating your home, office, or retail space, our tile and flooring experts are
here to guide you through the entire process.
We take pride in delivering tailored designs, reliable installation services, and lasting solutions
that enhance your property&#39;s aesthetics, functionality, and value.
Choose us as your trusted partner for all your tiles and flooring needs. Experience the difference
between our top-notch products, competitive pricing, and superior customer service. Please
explore our website today to see our extensive collection, schedule a consultation, or learn more
about our tile and flooring solutions.
                </p>
                <p>
                    <b>Let us help you create spaces that truly reflect your unique style and vision.</b>
                </p>
            </div>
            {/*<!-- History content End -->*/}

            {/*<!-- History Icon End -->*/}
            <div className="history-icon text-center">
                <img src="../assets/images/icon/icon-5.jpg" alt="Icon" />
            </div>
            {/*<!-- History Icon End -->*/}
        </div>
    </div>
   {/* <!-- History Section End -->*/}

   {/* <!-- Images Gallery Section Start -->*/}
    <div className="section section-padding">
        <div className="container">
            <div className="row">
                <div className="col-lg-8">
                    {/*<!-- Image Gallery Start -->*/}
                    <div className="image-gallery">
                        <img src="../assets/images/images-gallery/image-1.jpg" width="770" height="290" alt="gallery" />
                    </div>
                   {/*<!-- Image Gallery End -->*/}
                </div>
                <div className="col-lg-4">
                    {/*<!-- Image Gallery Start -->*/}
                    <div className="image-gallery">
                        <img src="../assets/images/images-gallery/image-2.jpg" width="370" height="290" alt="gallery" />
                    </div>
                   {/*<!-- Image Gallery End -->*/}
                </div>
                <div className="col-lg-6">
                    {/*<!-- Image Gallery Start -->*/}
                    <div className="image-gallery">
                        <img src="../assets/images/images-gallery/image-3.jpg" height="570" height="290" alt="gallery" />
                    </div>
                   {/*<!-- Image Gallery End -->*/}
                </div>
                <div className="col-lg-6">
                    {/*<!-- Image Gallery Start -->*/}
                    <div className="image-gallery">
                        <img src="../assets/images/images-gallery/image-4.jpg" width="570" height="290" alt="gallery" />
                    </div>
                   {/*<!-- Image Gallery End -->*/}
                </div>
            </div>
        </div>
    </div>
    {/*<!-- Images Gallery Section End -->*/}

    
    <div className="section bg-color-01">
        <div className="products-banner products-banner-active">
            <div className="swiper-container">
            <Swiper
                slidesPerView={2}
                spaceBetween={0}
                navigation={false}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
                modules={[ Navigation ]}
                className="mySwiper"
              >
              {/*{categories && categories.slice(0,4).map((item) => (
                                <li key={item._id} className={splitLocation[1] === `shop/category/${item.name}` ? "active" : ``}>
                                    <Link to={`/shop/category/${item.name}`}>{item.name}</Link>
                                </li>
                            ))}*/}
            {categories && categories.map((item) => (
                <SwiperSlide key={item._id}>
                    <div className="swiper-slide">
                        {/*<!-- Single Products Banner Start -->*/}
                        <div className="single-products-banner">
                            <img src={item.images[0]?.url} width="480" height="600" alt="Category" />

                            <div className="products-banner-content">
                                <div className="banner-content-wrapper">
                                    <h4 className="title">
                                        <Link className="text-capitalize" to={`/shop/category/${item.name}`}>{item.name}</Link>
                                    </h4>
                                    <span className="products-count"
                                            >{products.filter(product => product.category === item.name).length} Products</span>
                                    <Link to={`/shop/category/${item.name}`} className="arrow"><i className="pe-7s-angle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/*<!-- Single Products Banner End -->*/}
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
                
            </div>
        </div>
    </div>
     </>
     )
        }


		</>
	)
}

export default About