import {createStore,combineReducers,applyMiddleware} from 'redux';
import thunk from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import {
  productsReducer,
  productDetailsReducer,
  newReviewReducer,
  newProductReducer,
  productReducer,
  productReviewsReducer,
  reviewReducer,
} from "./reducers/productReducer";
import {
  userReducer,
  profileReducer,
  forgotPasswordReducer,
  allUsersReducer,
  userDetailsReducer,
  logoReducer,
  getLogoReducer,
  singleLogoReducer,
  logoDetailsReducer,
  bannerReducer,
  getBannerReducer,
  singleBannerReducer,
  bannerDetailsReducer,
} from "./reducers/userReducer";
import {
  newOrderReducer,
  myOrdersReducer,
  orderDetailsReducer,
  allOrdersReducer,
  orderReducer,
} from "./reducers/orderReducer";
import { cartReducer } from "./reducers/cartReducer";
import { categoriesReducer,newCategoryReducer,categoryReducer,categoryDetailsReducer } from "./reducers/categoryReducer";

const reducer = combineReducers({
  products: productsReducer,
  productDetails: productDetailsReducer,
  user: userReducer,
  profile: profileReducer,
  forgotPassword: forgotPasswordReducer,
  cart: cartReducer,
  newOrder: newOrderReducer,
  myOrders: myOrdersReducer,
  orderDetails: orderDetailsReducer,
  newReview: newReviewReducer,
  newProduct: newProductReducer,
  product: productReducer,
  allOrders: allOrdersReducer,
  order: orderReducer,
  allUsers: allUsersReducer,
  userDetails: userDetailsReducer,
  productReviews: productReviewsReducer,
  review: reviewReducer,
  categories: categoriesReducer,
  newCategory: newCategoryReducer,
  category: categoryReducer,
  categoryDetails: categoryDetailsReducer,
  newlogo: logoReducer,
  logos: getLogoReducer,
  logo: singleLogoReducer,
  logoDetails: logoDetailsReducer,
  newbanner: bannerReducer,
  banners: getBannerReducer,
  banner: singleBannerReducer,
  bannerDetails: bannerDetailsReducer,
});

let initialState = {
  cart: {
    cartItems: localStorage.getItem("cartItems")
      ? JSON.parse(localStorage.getItem("cartItems"))
      : [],
    shippingInfo: localStorage.getItem("shippingInfo")
      ? JSON.parse(localStorage.getItem("shippingInfo"))
      : {},
  },
};


const middleware = [thunk];

const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);

export default store;