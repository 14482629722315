import React,{useRef,useState,useEffect} from 'react';
import {Link,useParams} from 'react-router-dom';
import { Swiper, SwiperSlide } from "swiper/react";
import Loader from "../components/Loader.js";
import { Modal } from 'react-bootstrap';
import { useAlert } from "react-alert";
import { useSelector, useDispatch } from "react-redux";
import "swiper/css/pagination";
// import { Pagination } from "swiper";
import { getAllCategories } from "../actions/categoryAction";
import { getProduct } from "../actions/productAction";
import blogs from '../blogs';
// Import Swiper styles
// import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
// import required modules
import { EffectFade,Autoplay,Navigation,Pagination,FreeMode,Thumbs } from "swiper";
const BlogDetail = () => {

    const { name } = useParams();
    const dispatch = useDispatch();
    const {  categories } = useSelector((state) => state.categories);
    const { loading, error, products } = useSelector((state) => state.products);

    
    const blog = blogs.find((blog) => blog.name.replace(/\s+/g, '-') === name.replace(/\s+/g, '-'));
    const { title, description, image } = blog;

    useEffect(() => {
        dispatch(getProduct());
        dispatch(getAllCategories());
        
    }, [dispatch, error, alert]);

   

	return (
		<>
        {
            loading? ( <Loader />) :(

               <>

			{/*<!-- Page Banner Section Start -->*/}
    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    <div className="overlayBanner"></div>
        <div className="container">
            {/*<!-- Page Banner Content End -->*/}
            <div className="page-banner-content">
                <h2 className="title">Blog Detail</h2>

                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li className="active">Blog Detail</li>
                </ul>
            </div>
            {/*<!-- Page Banner Content End -->*/}
        </div>
    </div>
    {/*<!-- Page Banner Section End -->*/}

    {/*<!-- Blog Section Start -->*/}
    <div className="section section-padding custom_blogs">
        <div className="container">
            {/*<!-- Blog Wrapper Start -->*/}
            <div className="blog-wrapper">
                <div className="row">
                
                    <div className="">
                        <h3>{title}</h3>
                        <img className="my-4" src={image} alt={name} style={{width : '100%'}}/>

                        <div style={{textAlign : 'justify'}} dangerouslySetInnerHTML={{ __html: description }}>
                        
                                {/*{description}*/}
                        
                        </div>
                    </div>
                  
                </div>
            </div>
            {/*<!-- Blog Wrapper End -->*/}

            
        </div>
    </div>
    {/*<!-- Blog Section End -->*/}

    
    <div className="section bg-color-01">
        <div className="products-banner products-banner-active">
            <div className="swiper-container">
            <Swiper
                slidesPerView={2}
                spaceBetween={0}
                navigation={false}
                loop={true}
                breakpoints={{
                  640: {
                    slidesPerView: 2,
                    spaceBetween: 0,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 0,
                  },
                  1024: {
                    slidesPerView: 4,
                    spaceBetween: 0,
                  },
                }}
                modules={[ Navigation ]}
                className="mySwiper"
              >
              {/*{categories && categories.slice(0,4).map((item) => (
                                <li key={item._id} className={splitLocation[1] === `shop/category/${item.name}` ? "active" : ``}>
                                    <Link to={`/shop/category/${item.name}`}>{item.name}</Link>
                                </li>
                            ))}*/}
            {categories && categories.map((item) => (
                <SwiperSlide key={item._id}>
                    <div className="swiper-slide">
                        {/*<!-- Single Products Banner Start -->*/}
                        <div className="single-products-banner">
                            <img src={item.images[0]?.url} width="480" height="600" alt="Category" />

                            <div className="products-banner-content">
                                <div className="banner-content-wrapper">
                                    <h4 className="title">
                                        <Link className="text-capitalize" to={`/shop/category/${item.name}`}>{item.name}</Link>
                                    </h4>
                                    <span className="products-count"
                                            >{products.filter(product => product.category === item.name).length} Products</span>
                                    <Link to={`/shop/category/${item.name}`} className="arrow"><i className="pe-7s-angle-right"></i></Link>
                                </div>
                            </div>
                        </div>
                        {/*<!-- Single Products Banner End -->*/}
                    </div>
                </SwiperSlide>
            ))}
            </Swiper>
                
            </div>
        </div>
    </div>
    </>
     )
        }

        
			
		</>
	)
}

export default BlogDetail