import React from 'react';
import {Link} from 'react-router-dom';

const TermCondition = () => {
	return (
		<>
			{/*<!-- Page Banner Section Start -->*/}
		    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    		<div className="overlayBanner"></div>
		        <div className="container">
		            {/*<!-- Page Banner Content End -->*/}
		            <div className="page-banner-content">
		                <h2 className="title">Terms and Conditions</h2>

		                <ul className="breadcrumb">
		                    <li><Link to="/">Home</Link></li>
		                    <li className="active">Terms and Conditions</li>
		                </ul>
		            </div>
		            {/*<!-- Page Banner Content End -->*/}
		        </div>
		    </div>
		    {/*<!-- Page Banner Section End -->*/}	


		    {/*<!-- Contact Section Start -->*/}
		    <div className="section section-padding">
		        <div className="container">
		            {/*<!-- Contact Wrapper Start -->*/}
		            		
		            	<h3 className="mb-2">Terms and Conditions</h3>
            			<ol style={{listStyleType : 'auto'}}>
            				<li>
            					Tiles and Flooring Shop (TFS) catalogues, price lists, and other advertising materials do
not form a contractual agreement between the buyer and the company. Any price
quotations provided by the company or employees are for informational purposes only
and do not constitute an offer to sell.
            				</li>
            				<li>
            					Unless explicitly stated in writing, all prices quoted are exclusive of VAT. Quotations
validity is 30 days from the date of issue.
            				</li>
            				<li>
            					The buyer must notify the company via email or phone within 48 hours of delivery
regarding any claims of shortages, incorrect tiles, or goods damaged during transit. The
risk associated with the goods transfers to the buyer upon delivery.
            				</li>
            				<li>
            					Shading variations are inherent qualities of ceramic tiles, and the buyer must order the
appropriate quantity based on their requirements. TFS cannot guarantee against crazing
or shade variation.
            				</li>
            				<li>
            					The number of tiles per square yard or square meter is nominal, and the buyer must
ensure an adequate quantity is ordered to cover the required area. We recommend adding
10% to account for waste and cuts.
            				</li>
            				<li>
            					Cancelled orders will incur collection and transportation charges. We strive to deliver
orders promptly and, subject to stock availability and timing, make every effort to pack
and dispatch orders on the same day they are received. Suppose an order is cancelled
after loading it onto the delivery vehicle. In that case, a charge may apply to cover the
expenses incurred in returning the pallet to TFS for resale.
            				</li>
            				<li>
            					Orders are accepted, and delivery promises are made based on our ability to secure the
necessary materials. We are not responsible for delays caused by manufacturing risks,
strikes, accidents, or other factors beyond our control.
            				</li>
            				<li>
            					Invoices will be dated in the month when the goods are dispatched from TFS. All
amounts are strictly net, and credit extensions cannot be granted.
            				</li>
            				<li>
            					If we have not received payment within the due date, we have the right to charge interest
at a rate of 5% above the Bank of England base rate applicable at that time. Interest will
be payable from the original due date until the payment is made.
            				</li>
            				<li>
            					No compliance with the payment terms will result in immediate payment, including any
applicable interest.
            				</li>
            				<li>
            					Before installation, please carefully inspect your tiles. Shade variation is a design
characteristic of most of our tiles. Once tiles have been fixed, we cannot accept
responsibility for any shade-related issues. Setting the tiles to a substrate implies
acceptance of their current state, and no refunds or replacements will be offered. Please
ensure you are satisfied with the tile shade before starting your project.
            				</li>
            				<li>
            					The supply of goods does not imply suitability for a specific installation. We can only be
held responsible for failures of products to the extent that the manufacturer guarantees
them. No tiles are guaranteed against crazing.
            				</li>
            				<li>
            					Prices can be changed without prior notice.
            				</li>
            				<li>
            					The company cannot entertain any financial claims from customers for late or incorrect
deliveries under any circumstances. We strongly advise against booking a tiler until the
tiles have been delivered.
            				</li>
            				<li>
            				Tiles will be shrink-wrapped on a pallet and delivered to the ground level of your
property. Due to insurance reasons, the driver cannot assist in moving the tiles into your
property. A parcel courier will deliver orders weighing under 20kg.
            				</li>
            				<li>
            				Sample tiles are available at £0.99 each. Please note that specific exclusive tiles may not
have an option for sample requests on the product page. For sample inquiries, please get
in touch with our sales support team.
            				</li>
            				<li>
            				Returned tiles must be in a re-saleable condition. We recommend placing the tiles back
on the pallet they were delivered on and securely fastening them to prevent movement.
Taking photographs before and after wrapping the tiles is helpful in case of potential
transit damage. Due to batching issues, returns can only be accepted within 30 days of
tile delivery.
            				</li>
            				<li>
            				We are committed to protecting your privacy. Any information collected from individual
customers is used solely by authorised employees within the company on a need-to-know
basis. We continually monitor our data and systems to ensure the best service to our
customers. Further, unauthorised actions against computer systems and data are subject to
investigation, prosecution, and civil proceedings to recover damages.
            				</li>
            				<li>
            				UK legislation will be followed in case of dispute settlements with overseas customers.
            				</li>
            				<li>
            				Customers are advised to buy a sample tile before placing their order to ensure
satisfaction and to account for any potential batch differences.
            				</li>
            				<li>
            				If broken or damaged tiles, customers must provide sufficient evidence of damage to
proceed with replacements or a refund for the damaged amount.
            				</li>
            				<li>
            				The company cannot entertain any financial claims from customers for late or incorrect
deliveries under any circumstances.
            				</li>
            				<li>
            				As a company, we advise against booking a tiler before receiving and inspecting the tiles
for completeness and accuracy. The company will not be held liable for any fees resulting
from delays.
            				</li>
            			</ol>

		            {/*<!-- Contact Wrapper End -->*/}
		        </div>
		    </div>
		    {/*<!-- Contact Section End -->*/}


		</>
	)
}

export default TermCondition