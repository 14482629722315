import React,{useEffect, useState} from 'react';
import {Link} from 'react-router-dom';
import Faq from "react-faq-component";

const data = {
    title: "FAQs",
    rows: [
        {
            title: "How do I choose suitable tiles for my project?",
            content: `Visit our tile showroom to explore a wide range of options. Our tile specialists can help you find the
			perfect tiles for your project.`,
        },
        {
            title: "What is the recommended way to clean and maintain my tiles?",
            content:
                "Follow our tile maintenance tips to keep your tiles looking their best. Our experts can guide cleaning and maintenance for different tile types.",
        },
        {
            title: "Can I use tiles for both indoor and outdoor applications?",
            content: `Yes, our high-quality tiles are suitable for both indoor and outdoor use. We offer a wide selection of
			outdoor tiles designed for durability and style.`,
        },
        {
            title: "Where can I find a reputable tile store?",
            content: `Look no further! Our tile store offers a diverse range of tiles for all your needs. Visit our showroom or
			explore our online selection today.`,
        },
        {
            title: "Do you provide tile installation services?",
            content: `Absolutely! We have experienced flooring contractors who can handle your tile installation with precision
and professionalism.`,
        },
        {
            title: "How can I get expert tile installation?",
            content: `Leave the tile installation to our flooring specialists. We have the knowledge and skills to ensure a
flawless installation that exceeds your expectations.`,
        },
        {
            title: "Can I find specific tiles, such as bathroom or kitchen tiles?",
            content: `Yes, we have a dedicated selection of bathroom and kitchen tiles to enhance the style and functionality of
these spaces. Visit our showroom to explore the options.`,
        },
        {
            title: "Are you a leading supplier of outdoor tiles?",
            content: `Yes, we are a leading supplier of outdoor tiles. Our extensive collection includes weather-resistant and
durable tiles perfect for your outdoor projects.`,
        },
        {
            title: "Where can I find a reliable tile supplier?",
            content: `Look no further! We are a trusted tile supplier, offering high-quality tiles for various applications. Visit
our showroom or browse our selection online.`,
        },
        {
            title: "Do you offer wood flooring options?",
            content: `Yes, we offer various wood flooring options, including hardwood flooring and laminate flooring, that can
add warmth and elegance to your space.`,
        },
        {
            title: "Where can I find affordable and high-quality vinyl flooring?",
            content: `Look no further! Our tile showroom offers a wide selection of affordable and high-quality vinyl flooring
options to suit your budget and style preferences.`,
        },
        {
            title: "Are your ceramic tiles suitable for both residential and commercial applications?",
            content: `Absolutely! Our versatile ceramic tiles can be used in residential and commercial settings. Visit our
showroom to explore our ceramic tile options.`,
        },
        {
            title: "Do you offer many porcelain tiles, including large-format options?",
            content: `Yes, we have an extensive collection of porcelain tiles, including large-format options, perfect for
creating a sleek and modern look. Visit our tile showroom to explore the range.`,
        },
        {
            title: "Are natural stone tiles available for purchase?",
            content: `Yes, we offer a beautiful selection of natural stone tiles, including marble tiles, travertine tiles, and more.
Visit our showroom to see the stunning natural stone options.`,
        },
        {
            title: "Can I find mosaic tiles for creating a unique and intricate design?",
            content: `Yes, we have a range of mosaic tiles that can add elegance and artistry to your space. Visit our showroom
to discover the possibilities of mosaic tile designs.`,
        },
        {
            title: "Where can I find a wide selection of tile backsplash options for my kitchen?",
            content: `Look no further! Our tile showroom offers a wide selection of tile backsplash options to enhance the
beauty and functionality of your kitchen. Visit us today to explore the range.`,
        },
        {
            title: "Are you a trusted provider of flooring renovation services?",
            content: `Yes, we are a trusted provider of flooring renovation services. Our experienced team can help transform
your space with quality tile and flooring options. Contact us for more information.`,
        },
        {
            title: "Do you provide custom tile design services?",
            content: `Absolutely! Our experts can help bring your unique vision to life with our custom tile design services.
Contact us, and we will respond to your to discuss your specific requirements and design ideas.`,
        },
        {
            title: "Can I get expert advice on tile and flooring maintenance?",
            content: `Yes, our knowledgeable team can provide expert advice and tips on tile and flooring maintenance. We are
here to ensure your tiles, and flooring look their best for years to come.`,
        },
        {
            title: "What are the latest tile and flooring trends in 2023?",
            content: `Visit our showroom to stay updated with the latest tile and flooring trends in 2023. Our experts can help
you incorporate the latest styles and designs into your space.`,
        },
        {
            title: "Can I find eco-friendly and sustainable flooring options at your tile and flooring store?",
            content: `Yes, we offer many eco-friendly and sustainable flooring options, including materials such as bamboo
and cork. Visit our showroom to explore our environmentally friendly choices.`,
        },
        {
            title: "Are you a trusted supplier of commercial flooring solutions?",
            content: `Absolutely! We are a trusted supplier of commercial flooring solutions, providing high-quality and
durable options for various commercial spaces. Contact us for tailored recommendations.`,
        },
        {
            title: "Do you offer discounts or promotions on tiles and flooring?",
            content: `Yes, we frequently offer discounts and promotions on our tiles and flooring products. Stay updated by
checking our website or contacting our team for current offers.`,
        },
        {
            title: "Where can I find tile and flooring experts in 2023?",
            content: `Look no further! Our team of tile and flooring experts is ready to assist you with all your needs. Visit our
showroom or contact us for professional advice and guidance.`,
        },
        {
            title: "Can you guide tile and flooring installation?",
            content: `Certainly! Our experienced team can provide guidance and instructions on tile and flooring installation.
We&#39;re here to ensure your project succeeds from start to finish.`,
        },
        {
            title: "What are the benefits of choosing hardwood flooring for my home?",
            content: `Hardwood flooring offers timeless beauty and durability. Visit our tile and flooring store to explore a
wide selection of high-quality hardwood flooring options for your home.`,
        },
        {
            title: "Can I find luxury tile and flooring options at your showroom?",
            content: `Yes, our tile and flooring showroom offers a range of luxury options to elevate your space. From
exquisite tiles to premium flooring materials, we have your desired selection.`,
        },
        {
            title: "Are your tile and flooring products backed by quality guarantees?",
            content: `Absolutely! We stand behind the quality of our tile and flooring products. We select each item to meet
our high standards and have a quality guarantee.`,
        },

        {
            title: "Can I get professional assistance selecting the right tile and flooring options?",
            content: `Yes, our knowledgeable team is here to provide professional assistance in selecting the perfect tile and
flooring options for your project. Visit our showroom or contact us for personalised guidance.`,
        },
        {
            title: "Where can I find the latest tile and flooring designs and inspiration?",
            content: `Look no further! Our tile and flooring showroom features the latest designs and inspires your next project.
Explore our collection to discover the possibilities.`,
        },
        {
            title: "Do you offer professional flooring installation services?",
            content: `Yes, we provide professional flooring installation services to ensure a seamless and expertly installed
floor. Our experienced team will handle the installation process with precision.`,
        },
        {
            title: "Are there tile and flooring options suitable for small spaces?",
            content: `Absolutely! We offer various tile and flooring options perfect for small spaces, including compact designs
and light colours that make your space appear larger and more open.`,
        },
        {
            title: "Where can I find reliable tile and flooring maintenance tips?",
            content: `Look no further! Our website provides reliable tile and flooring maintenance tips to help you keep your
floors looking pristine. Follow our expert advice for proper care and maintenance.`,
        },
        {
            title: "Can I get assistance with tile and flooring design ideas?",
            content: `Yes, our knowledgeable team provide you with tile and flooring design ideas tailored to your preferences.
From modern to traditional styles, we can help you find the perfect inspiration for your project.`,
        },
        {
            title: "What are the advantages of choosing waterproof flooring for my home?",
            content: `Waterproof flooring offers excellent protection against moisture, making it a suitable choice for areas
prone to spills and high humidity. Visit our showroom to explore our waterproof flooring options.`,
        },
        {
            title: "Can your store offer affordable and durable tile and flooring options?",
            content: `Yes, we offer various affordable and durable tile and flooring options to suit different budgets. Visit our
store to explore our cost-effective solutions.`,
        },
        {
            title: "Where can I find expert advice on tile and flooring installation guides?",
            content: `Look no further! Our website provides expert advice and comprehensive installation guides to assist you
in achieving a successful tile and flooring installation. Explore our resources for detailed instructions.`,
        },
        {
            title: "Are there tile and flooring solutions suitable for high-traffic areas?",
            content: `Absolutely! We offer tile and flooring solutions specifically designed to withstand high-traffic areas. Our
durable and resilient options can maintain their beauty even with heavy footfall.`,
        },
        {
            title: "Do you provide tile and flooring maintenance services?",
            content: `While we do not provide maintenance services directly, we can offer guidance and recommendations on
suitable tile and flooring maintenance practices. Contact us for expert tips.`,
        },
        {
            title: "Can I find custom tile design services for unique and personalised projects?",
            content: `Yes, we offer custom tile design services to bring your unique vision to life. Our skilled designers can
assist you in creating bespoke patterns and layouts for a truly personalised touch.`,
        },
    ],
};

const styles = {
    // bgColor: 'white',
    titleTextColor: "#383838",
    rowTitleColor: "#383838",
    // rowContentColor: 'grey',
    // arrowColor: "red",
};

const config = {
    // animate: true,
    // arrowIcon: "V",
    // tabFocus: true
    // openOnload: 1,
};



const FaqPage = () => {

    const [rows, setRowsOption] = useState(null);

useEffect(() => {
        if (rows) {
            for (let i = 0; i < rows.length; i++) {
                rows[i].expand();
            }    
        }
    }, [rows]);

	return (
		<>
			
			{/*<!-- Page Banner Section Start -->*/}
    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    <div className="overlayBanner"></div>
        <div className="container">
            {/*<!-- Page Banner Content End -->*/}
            <div className="page-banner-content">
                <h2 className="title">Faqs</h2>

                <ul className="breadcrumb">
                    <li><Link to="/">Home</Link></li>
                    <li className="active">Faqs</li>
                </ul>
            </div>
            {/*<!-- Page Banner Content End -->*/}
        </div>
    </div>
    {/*<!-- Page Banner Section End -->*/}

    {/*<!-- Contact Section Start -->*/}
    <div className="section section-padding">
        <div className="container">
            {/*<!-- Contact Wrapper Start -->*/}
            		
            	<Faq
	                data={data} getRowOptions={setRowsOption}
	                styles={styles}
	                config={config}
	            />

            {/*<!-- Contact Wrapper End -->*/}
        </div>
    </div>
    {/*<!-- Contact Section End -->*/}
			
		</>
	)
}

export default FaqPage