import React from 'react';
import {Link} from 'react-router-dom';

const PaymentMethod = () => {
	return (
		<>
			{/*<!-- Page Banner Section Start -->*/}
		    <div className="section page-banner-section" style={{backgroundImage: 'url(../assets/images/banner.webp)'}}>
    		<div className="overlayBanner"></div>
		        <div className="container">
		            {/*<!-- Page Banner Content End -->*/}
		            <div className="page-banner-content">
		                <h2 className="title">Payment Methods</h2>

		                <ul className="breadcrumb">
		                    <li><Link to="/">Home</Link></li>
		                    <li className="active">Payment Methods</li>
		                </ul>
		            </div>
		            {/*<!-- Page Banner Content End -->*/}
		        </div>
		    </div>
		    {/*<!-- Page Banner Section End -->*/}	


		    {/*<!-- Contact Section Start -->*/}
		    <div className="section section-padding">
		        <div className="container">
		            {/*<!-- Contact Wrapper Start -->*/}
		            		
		            	<h3 className="mb-2">Payment Methods</h3>
            	<p>Accepted Payment Methods At our online store, we offer various convenient payment methods
to accommodate your preferences. You can pay online, over the phone, via fax, or by mail. Also,
we accept credit cards, including Visa, MasterCard, Diners, and JCB.</p>

					<h4>Security Measures</h4>
					<p>
						We prioritise the security of your personal information. When you provide sensitive data on our
order forms, such as credit card numbers, we employ secure socket layer technology (SSL) to
encrypt the information. It ensures that your data remains protected during transmission.
					</p>

					<p>
					We adhere to widely accepted industry standards to safeguard the personal information you
submit. While we make every effort to employ commercially acceptable measures to secure your
data, it is important to note that transmission methods over the Internet or electronic storage are
only partially foolproof.
					</p>

					<p>
						Although we strive to protect your personal information, we cannot guarantee absolute security.
If you have any concerns or inquiries regarding our website security, please contact us via email
at <Link to="">info@tilesandflooring.shop</Link>.
					</p>

					<h4>Credit Card Information</h4>
					<p>
						When purchasing on our website, you must only provide credit card details. Rest assured, these
details are collected on Secure Trading&#39;s secure site and are solely used for processing purposes
by Secure Trading. Furthermore, we cannot access or store your credit card details on our site.
					</p>

					<h4>ID [Address] Verification</h4>
					<p>
						We employ Secure Trading services to verify credit card information, ensure credit card
transactions&#39; legitimacy, and protect against fraudulent activities. This crucial process verifies the
user&#39;s identity as the authorised cardholder, adding an extra layer of security to our operations.
					</p>

		            {/*<!-- Contact Wrapper End -->*/}
		        </div>
		    </div>
		    {/*<!-- Contact Section End -->*/}


		</>
	)
}

export default PaymentMethod