import axios from "axios";
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  REGISTER_USER_REQUEST,
  REGISTER_USER_SUCCESS,
  REGISTER_USER_FAIL,
  LOAD_USER_REQUEST,
  LOAD_USER_SUCCESS,
  LOAD_USER_FAIL,
  LOGOUT_SUCCESS,
  LOGOUT_FAIL,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_SUCCESS,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_RESET,
  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_RESET,
  UPDATE_PASSWORD_FAIL,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAIL,
  RESET_PASSWORD_REQUEST,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  ALL_USERS_REQUEST,
  ALL_USERS_SUCCESS,
  ALL_USERS_FAIL,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAIL,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAIL,
  USER_DETAILS_REQUEST,
  USER_DETAILS_SUCCESS,
  USER_DETAILS_FAIL,
  UPLOAD_LOGO_REQUEST,
  UPLOAD_LOGO_SUCCESS,
  UPLOAD_LOGO_RESET,
  UPLOAD_LOGO_FAIL,
  GET_LOGO_REQUEST,
  GET_LOGO_SUCCESS,
  GET_LOGO_FAIL,
  GET_LOGO_ADMIN_REQUEST,
  GET_LOGO_ADMIN_SUCCESS,
  GET_LOGO_ADMIN_FAIL,
  DELETE_LOGO_REQUEST,
  DELETE_LOGO_SUCCESS,
  DELETE_LOGO_FAIL,
  DELETE_LOGO_RESET,
  DETAILS_LOGO_REQUEST,
  DETAILS_LOGO_SUCCESS,
  DETAILS_LOGO_FAIL,
  LOGO_UPDATED_REQUEST,
  LOGO_UPDATED_SUCCESS,
  LOGO_UPDATED_RESET,
  LOGO_UPDATED_FAIL,
  UPLOAD_BANNER_REQUEST,
  UPLOAD_BANNER_SUCCESS,
  UPLOAD_BANNER_RESET,
  UPLOAD_BANNER_FAIL,
  GET_BANNER_REQUEST,
  GET_BANNER_SUCCESS,
  GET_BANNER_FAIL,
  GET_BANNER_ADMIN_REQUEST,
  GET_BANNER_ADMIN_SUCCESS,
  GET_BANNER_ADMIN_FAIL,
  DELETE_BANNER_REQUEST,
  DELETE_BANNER_SUCCESS,
  DELETE_BANNER_FAIL,
  DELETE_BANNER_RESET,
  DETAILS_BANNER_REQUEST,
  DETAILS_BANNER_SUCCESS,
  DETAILS_BANNER_FAIL,
  BANNER_UPDATED_REQUEST,
  BANNER_UPDATED_SUCCESS,
  BANNER_UPDATED_RESET,
  BANNER_UPDATED_FAIL,
  CLEAR_ERRORS,
} from "../constants/userConstant";

// Login
export const login = (email, password) => async (dispatch) => {
  try {
    dispatch({ type: LOGIN_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(
      `/api/v1/login`,
      { email, password },
      config
    );

    dispatch({ type: LOGIN_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOGIN_FAIL, payload: error.response.data.message });
  }
};

// Logo
export const logo = (logoData) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_LOGO_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(`/api/v1/admin/logo`, logoData, config);

    dispatch({ type: UPLOAD_LOGO_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPLOAD_LOGO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// banner
export const banner = (bannerData) => async (dispatch) => {
  try {
    dispatch({ type: UPLOAD_BANNER_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(`/api/v1/admin/banner`, bannerData, config);

    dispatch({ type: UPLOAD_BANNER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: UPLOAD_BANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Logo
export const getlogo = () => async (dispatch) => {
  try {
    dispatch({ type: GET_LOGO_REQUEST });

    const { data } = await axios.get("/api/v1/logos");

    dispatch({
      type: GET_LOGO_SUCCESS,
      payload: data.logos,
    });
  } catch (error) {
    dispatch({
      type: GET_LOGO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Banner
export const getbanner = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BANNER_REQUEST });

    const { data } = await axios.get("/api/v1/banners");

    dispatch({
      type: GET_BANNER_SUCCESS,
      payload: data.banners,
    });
  } catch (error) {
    dispatch({
      type: GET_BANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get Logo by admin
export const getlogobyadmin = () => async (dispatch) => {
  try {
    dispatch({ type: GET_LOGO_ADMIN_REQUEST });

    const { data } = await axios.get("/api/v1/admin/logos");

    dispatch({
      type: GET_LOGO_ADMIN_SUCCESS,
      payload: data.logos,
    });
  } catch (error) {
    dispatch({
      type: GET_LOGO_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get banner by admin
export const getbannerbyadmin = () => async (dispatch) => {
  try {
    dispatch({ type: GET_BANNER_ADMIN_REQUEST });

    const { data } = await axios.get("/api/v1/admin/banners");

    dispatch({
      type: GET_BANNER_ADMIN_SUCCESS,
      payload: data.banners,
    });
  } catch (error) {
    dispatch({
      type: GET_BANNER_ADMIN_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Register
export const register = (userData) => async (dispatch) => {
  try {
    dispatch({ type: REGISTER_USER_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.post(`/api/v1/register`, userData, config);

    dispatch({ type: REGISTER_USER_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({
      type: REGISTER_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Load User
export const loadUser = () => async (dispatch) => {
  try {
    dispatch({ type: LOAD_USER_REQUEST });

    const { data } = await axios.get(`/api/v1/me`);

    dispatch({ type: LOAD_USER_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: LOAD_USER_FAIL, payload: error.response.data.message });
  }
};

// Logout User
export const logout = () => async (dispatch) => {
  try {
    await axios.get(`/api/v1/logout`);

    dispatch({ type: LOGOUT_SUCCESS });
  } catch (error) {
    dispatch({ type: LOGOUT_FAIL, payload: error.response.data.message });
  }
};

// Update Profile
export const updateProfile = (userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PROFILE_REQUEST });

    const config = { headers: { "Content-Type": "multipart/form-data" } };

    const { data } = await axios.put(`/api/v1/me/update`, userData, config);

    dispatch({ type: UPDATE_PROFILE_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update Password
export const updatePassword = (passwords) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `/api/v1/password/update`,
      passwords,
      config
    );

    dispatch({ type: UPDATE_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Forgot Password
export const forgotPassword = (email) => async (dispatch) => {
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.post(`/api/v1/password/forgot`, email, config);

    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data.message });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Reset Password
export const resetPassword = (token, passwords) => async (dispatch) => {
  try {
    dispatch({ type: RESET_PASSWORD_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `/api/v1/password/reset/${token}`,
      passwords,
      config
    );

    dispatch({ type: RESET_PASSWORD_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: RESET_PASSWORD_FAIL,
      payload: error.response.data.message,
    });
  }
};

// get All Users
export const getAllUsers = () => async (dispatch) => {
  try {
    dispatch({ type: ALL_USERS_REQUEST });
    const { data } = await axios.get(`/api/v1/admin/users`);

    dispatch({ type: ALL_USERS_SUCCESS, payload: data.users });
  } catch (error) {
    dispatch({ type: ALL_USERS_FAIL, payload: error.response.data.message });
  }
};

// get  User Details
export const getUserDetails = (id) => async (dispatch) => {
  try {
    dispatch({ type: USER_DETAILS_REQUEST });
    const { data } = await axios.get(`/api/v1/admin/user/${id}`);

    dispatch({ type: USER_DETAILS_SUCCESS, payload: data.user });
  } catch (error) {
    dispatch({ type: USER_DETAILS_FAIL, payload: error.response.data.message });
  }
};

// Update User
export const updateUser = (id, userData) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_USER_REQUEST });

    const config = { headers: { "Content-Type": "application/json" } };

    const { data } = await axios.put(
      `/api/v1/admin/user/${id}`,
      userData,
      config
    );

    dispatch({ type: UPDATE_USER_SUCCESS, payload: data.success });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete User
export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_USER_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/user/${id}`);

    dispatch({ type: DELETE_USER_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update logo
export const updateLogo = (id, logoData) => async (dispatch) => {
  try {
    dispatch({ type: LOGO_UPDATED_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/admin/logo/${id}`,
      logoData,
      config
    );

    dispatch({
      type: LOGO_UPDATED_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type:   LOGO_UPDATED_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Update banner
export const updateBanner = (id, bannerData) => async (dispatch) => {
  try {
    dispatch({ type: BANNER_UPDATED_REQUEST });

    const config = {
      headers: { "Content-Type": "application/json" },
    };

    const { data } = await axios.put(
      `/api/v1/admin/banner/${id}`,
      bannerData,
      config
    );

    dispatch({
      type: BANNER_UPDATED_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type:   BANNER_UPDATED_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete logo
export const deletelogo = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_LOGO_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/logo/${id}`);

    dispatch({
      type: DELETE_LOGO_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_LOGO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Delete banner
export const deletebanner = (id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_BANNER_REQUEST });

    const { data } = await axios.delete(`/api/v1/admin/banner/${id}`);

    dispatch({
      type: DELETE_BANNER_SUCCESS,
      payload: data.success,
    });
  } catch (error) {
    dispatch({
      type: DELETE_BANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get logo Details
export const getSingleLogo = (id) => async (dispatch) => {
  try{
    dispatch({
      type: DETAILS_LOGO_REQUEST,
    });

    const {data} = await axios.get(`/api/v1/logo/${id}`);

    dispatch({
      type: DETAILS_LOGO_SUCCESS,
      payload: data.logo,
    });
  }catch(error){
    dispatch({
      type: DETAILS_LOGO_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Get banner Details
export const getSingleBanner = (id) => async (dispatch) => {
  try{
    dispatch({
      type: DETAILS_BANNER_REQUEST,
    });

    const {data} = await axios.get(`/api/v1/banner/${id}`);

    dispatch({
      type: DETAILS_BANNER_SUCCESS,
      payload: data.banner,
    });
  }catch(error){
    dispatch({
      type: DETAILS_BANNER_FAIL,
      payload: error.response.data.message,
    });
  }
};

// Clearing Errors
export const clearErrors = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};