import React,{useMemo,useState,useEffect,useReducer} from 'react';
// import Sidebar from "./Sidebar";
import MaterialReactTable from 'material-react-table';
import { useSelector, useDispatch } from "react-redux";
import { Modal } from 'react-bootstrap';
import { Link,useParams,useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { clearErrors, getVendorProducts, deleteProduct, importFile, exportFile } from "../../actions/productAction";
import Loader from "../Loader";
import { Button } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import axios from 'axios';
import DeleteIcon from "@material-ui/icons/Delete";
import MetaData from '../../components/MetaData.js';
import download from "downloadjs";
import { DELETE_PRODUCT_RESET, IMPORT_PRODUCT_RESET, EXPORT_PRODUCT_RESET } from "../../constants/productConstant";


const VendorProductList = () => {

	const dispatch = useDispatch();
	// const {id} = useParams();
	const alert = useAlert();
	const [ViewDndModel, SetDndModelShow] = useState(false)
    const handleDndModelShow = () => { SetDndModelShow(true) }
    const hanldeDndModelClose = () => { SetDndModelShow(false) }
	const navigate = useNavigate();
	const [reducerValue, forceUpdate] = useReducer(x => x + 1, 0);
	const { loading, error, products } = useSelector((state) => state.products);

	const { success } = useSelector((state) => state.newProduct);
	const { error: deleteError, isDeleted } = useSelector((state) => state.product);

	const [selectedFile, setSelectedFile] = useState(null);

	const handleFileChange = (event) => {
	    setSelectedFile(event.target.files[0]);
	};

	const handleFileUpload = (e) => {
		e.preventDefault();
	    const formData = new FormData();
	    formData.append('file', selectedFile);

	    // axios
	    //   .post('http://localhost:4000/api/v1/importFile', formData, {
	    //     headers: {
	    //       'Content-Type': 'multipart/form-data',
	    //     },
	    //   })
	    //   .then((response) => {
	    //     console.log(response.data);
	    //     // Handle success message or perform any other action on successful upload
	    //   })
	    //   .catch((error) => {
	    //     console.error(error);
	    //     // Handle error message or perform any other action on failure
	    //   });
	    dispatch(importFile(formData));
	    // window.alert('ok');
	};

	const handleFileDownload = () => {
		
	    dispatch(exportFile());
	    alert.success("File Export Successfully");
	      dispatch({ type: EXPORT_PRODUCT_RESET });
	    // window.alert('ok');
	};

	useEffect(() => {
	    if (error) {
	      alert.error(error);
	      dispatch(clearErrors());
	    }

	    if (success) {
	      alert.success("File Import Successfully");
		  hanldeDndModelClose(true);
	      dispatch({ type: IMPORT_PRODUCT_RESET });
	    }

	    // if (success) {
	      
	    // }

	}, [dispatch, alert, error, success]);
	
	const deleteProductHandler = (id) => {
		// console.log('ok');
	    dispatch(deleteProduct(id));
	};

	const alertStock = (Stock) => {
		console.log('ok');
	    // dispatch(getAdminProducts(Stock));
	};

	useEffect(() => {
		if (error) {
		  alert.error(error);
		  dispatch(clearErrors());
		}

		if (deleteError) {
		  alert.error(deleteError);
		  dispatch(clearErrors());
		}

		if (isDeleted) {
		  alert.success("Product Deleted Successfully");
		  // navigate("/admin/dashboard");
		  dispatch({ type: DELETE_PRODUCT_RESET });
		}

		dispatch(getVendorProducts());
	}, [dispatch, alert, error, deleteError, isDeleted, navigate]);

	const columns = useMemo(
	    () => [
	      // {
	      //   accessorFn: (row) => row._id, //alternate way
	      //   id: '_id', //id required if you use accessorFn instead of accessorKey
	      //   header: 'Id',
	      //   Header: () => <span>Id</span>, //optional custom header render
	      // },
	      {
	        accessorFn: (row) => row.size, //alternate way
	        id: 'size', //id required if you use accessorFn instead of accessorKey
	        header: 'Size',
	        Header: () => <span>Size</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => row.series, //alternate way
	        id: 'series', //id required if you use accessorFn instead of accessorKey
	        header: 'Series',
	        Header: () => <span>Series</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => row.item_no, //alternate way
	        id: 'item_no', //id required if you use accessorFn instead of accessorKey
	        header: 'Item No.',
	        Header: () => <span>Item No.</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => row.name, //alternate way
	        id: 'name', //id required if you use accessorFn instead of accessorKey
	        header: 'Item Name',
	        Header: () => <span>Item Name</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => row.category, //alternate way
	        id: 'itemType', //id required if you use accessorFn instead of accessorKey
	        header: 'Item Type',
	        Header: () => <span>Item Type</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => <img src={row.images[0]?.url} width="110" height="110" alt="prod-img"/>, //alternate way
	        id: 'image', //id required if you use accessorFn instead of accessorKey
	        header: 'Image',
	        Header: () => <span>Image</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => <span>£{row.price}</span>, //alternate way
	        id: 'price', //id required if you use accessorFn instead of accessorKey
	        header: 'Price',
	        Header: () => <span>Price</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => row.status, //alternate way
	        id: 'status', //id required if you use accessorFn instead of accessorKey
	        header: 'Status',
	        Header: () => <span>Status</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => 
	        	<span>
	        		{
	        			row.qty 
	        			
						
	        		}
	        	</span>, //alternate way
	        id: 'qty', //id required if you use accessorFn instead of accessorKey
	        header: 'Qty',
	        Header: () => <span>Qty</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => 
	        	<span className={row.Stock <= 10 ? "text-danger" : "text-success"} onChange={alertStock}>
	        		{
	        			row.Stock 
	        			
						
	        		}
	        	</span>, //alternate way
	        id: 'stock', //id required if you use accessorFn instead of accessorKey
	        header: 'In Stock',
	        Header: () => <span>In Stock</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => 
	        	<span className={row.sold <= 10 ? "text-danger" : "text-success"} onChange={alertStock}>
	        		{
	        			row.qty - row.Stock 
	        			
						
	        		}
	        	</span>, //alternate way
	        id: 'sold', //id required if you use accessorFn instead of accessorKey
	        header: 'Sold',
	        Header: () => <span>Sold</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => <span>{row.description}</span>, //alternate way
	        id: 'description', //id required if you use accessorFn instead of accessorKey
	        header: 'Description',
	        Header: () => <span>Description</span>, //optional custom header render
	      },
	      {
	        accessorFn: (row) => <span>
	                            <Link to={`/vendor/update-product/${row._id}`}>
	                              <EditIcon />
	                            </Link>
	                            <Button onClick={() => deleteProductHandler(row._id)}>
	                              <DeleteIcon />
	                            </Button>
	                          </span>, //alternate way
	        id: 'action', //id required if you use accessorFn instead of accessorKey
	        header: 'Action',
	        Header: () => <span>Action</span>, //optional custom header render
	      },
	    ],
	    [],
	);

	return (
		<>
			{loading ? (
        <Loader />
      ) : (
      	<>

		      		<div className="dashboard-panel">
					<div className="container">
						<MetaData title={`ALL PRODUCTS - Admin`} />
						<div className="row">
							{/*<Sidebar />*/}	
							<div className="col-lg-12 col-md-12">
								<div className="dashboardContainer">
									<div className="d-flex justify-content-between align-items-center">
										<h3>All Products</h3>
										<div className="">
									        <Link to="/vendor/create-product" className="btn btn-secondary mx-2">Add Product</Link>
									        {/*<button onClick={() => { handleDndModelShow() }} className="btn btn-secondary mx-2">Import</button>
									        <button onClick={() => { handleFileDownload() }} className="btn btn-secondary">Export</button>*/}
										</div>
									</div>
							        <div className="col-12">
		                    			<MaterialReactTable columns={columns} data={products} enableRowNumbers rowNumberMode="original"/>
		  						    </div>
							    </div>
							</div>
						</div>
					</div>
					</div>

					{/*<!-- Modal -->*/}
		    <Modal show={ViewDndModel}
                    onHide={hanldeDndModelClose}
                    backdrop="static"
                    keyboard={false}
                    className="importFileModal"
                    >
				<h4>Import Products</h4>	
				
					<div className="single-form mb-3">
						<input type="file" onChange={handleFileChange}/>
					</div>
					<button onClick={handleFileUpload} className="m-1 btn btn-secondary">Upload</button>
					    
				<div className="form-group text-end">
					<button onClick={hanldeDndModelClose} className="m-1 btn btn-secondary">Close</button>
				</div>
			</Modal>
</>
		)}
		      	
		</>
	)
}

export default VendorProductList